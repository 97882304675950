
  import { computed, defineComponent, watch, ref, unref, nextTick } from 'vue';
  import { useWindowSize } from '@vueuse/core';
  import OpenAccountManageApplyDetail from '@/views/manage/ApplyDetail.vue';
  import { cacheStore } from '@/store/modules/cache';
  import { appStore } from '@/store/modules/app';
  import { isEmptyData } from '@/utils/common';
  export default defineComponent({
    name: 'WeDrawer',
    inheritAttrs: false,
    components: {
      OpenAccountManageApplyDetail,
    },
    props: {
      componentName: {
        type: String,
        default: '',
      },
      keyName: {
        type: String,
        default: '',
      },
    },
    setup(props) {
      const { width: wWidth } = useWindowSize();
      const width = computed(() => {
        return wWidth.value - 360;
      });
      const drawerVisible = ref(false);
      watch(
        () => props.componentName,
        (val) => {
          if (val !== '') {
            drawerVisible.value = true;
          } else {
            drawerVisible.value = false;
          }
        }
      );
      watch(
        () => unref(drawerVisible),
        (val) => {
          if (!val) {
            const reloadList = appStore.drawerConfig?.reloadList;
            !isEmptyData(reloadList) && reloadList();
            appStore.SET_DRAWER_CONFIG(null);
          }
          nextTick(() => {
            cacheStore.TOGGLE_SIDEBAR_COLLAPSED(val);
          });
        }
      );
      return {
        drawerVisible,
        width,
      };
    },
  });
