import type { PaginationProps } from '../types/pagination';
import type { BasicTableProps } from '../types/table';

import { computed, unref, ref, ComputedRef } from 'vue';
import isBoolean from 'lodash/isBoolean';
// interface ItemRender {
//   page: number;
//   type: 'page' | 'prev' | 'next';
//   originalElement: any;
// }
// 用于自定义页码的结构，可用于优化 SEO
// function itemRender({ page, type, originalElement }: ItemRender) {
//   if (type === 'prev') {
//     return page === 0 ? null : <svg-icon iconName="LeftOutlined" />;
//   }
//   if (type === 'next') {
//     return page === 1 ? null : <svg-icon iconName="RightOutlined" />;
//   }
//   if (type === 'page') {
//     return originalElement;
//   }
// }

export function usePagination(refProps: ComputedRef<BasicTableProps>) {
  const configRef = ref<PaginationProps>({});
  const show = ref(true);

  const getPaginationRef = computed((): PaginationProps | boolean => {
    const { pagination } = unref(refProps);

    if (!unref(show) || (isBoolean(pagination) && !pagination)) {
      return false;
    }

    return {
      current: 1,
      pageSize: 20,
      size: 'small',
      defaultPageSize: 20,
      pageSizeOptions: ['20', '50', '80', '100'],
      showQuickJumper: true,
      hideOnSinglePage: true,
      showTotal: (total) => `共${total}条`,
      ...(isBoolean(pagination) ? {} : (pagination as PaginationProps)),
      ...unref(configRef),
    };
  });

  function setPagination(info: Partial<PaginationProps>) {
    const paginationInfo = unref(getPaginationRef);
    configRef.value = {
      ...(!isBoolean(paginationInfo as PaginationProps) ? (paginationInfo as PaginationProps) : {}),
      ...info,
    };
  }

  return { getPaginationRef, setPagination };
}
