import { RouteRecordRaw } from 'vue-router';
import AppLayout from '@/layout/index.vue';

const account: RouteRecordRaw = {
  path: '/',
  name: 'OpenAccountManage',
  component: AppLayout,
  redirect: { name: 'OpenAccountManageIndex' },
  children: [
    {
      path: 'index',
      name: 'OpenAccountManageIndex',
      component: () => import('@/views/manage/index.vue'),
      meta: {
        title: '首页',
      },
    },
    {
      path: 'open-list',
      name: 'OpenAccountManageOpenList',
      component: () => import('@/views/manage/ApplyList.vue'),
      props: ({ params, query }) => ({ ...params, ...query }),
      meta: {
        title: '开户记录',
      },
    },
    {
      path: 'recharge-list',
      name: 'OpenAccountManageRechargeList',
      component: () => import('@/views/manage/RechargeList.vue'),
      props: ({ params, query }) => ({ ...params, ...query }),
      meta: {
        title: '充值记录',
      },
    },
    {
      path: 'recharge-detail',
      name: 'OpenAccountManageRechargeDetail',
      component: () => import('@/views/manage/RechargeDetail.vue'),
      props: ({ params, query }) => ({ ...params, ...query }),
      meta: {
        title: '充值详情',
      },
    },
    {
      path: 'submit-recharge',
      name: 'OpenAccountManageSubmitRechargeApply',
      component: () => import('@/views/manage/RechargeApply.vue'),
      props: ({ params, query }) => ({ ...params, ...query }),
      meta: {
        title: '提交充值申请',
      },
    },
    {
      path: 'account-list',
      name: 'OpenAccountManageAccountList',
      component: () => import('@/views/manage/AccountList.vue'),
      props: ({ params, query }) => ({ ...params, ...query }),
      meta: {
        title: '账户列表',
      },
    },
    {
      path: 'open-apply',
      name: 'OpenAccountManageApply',
      component: () => import('@/views/manage/OpenApply.vue'),
      props: ({ params, query }) => ({ ...params, ...query }),
      meta: {
        title: '开户申请',
      },
    },
    {
      path: 'apply-detail',
      name: 'OpenAccountManageApplyDetail',
      component: () => import('@/views/manage/ApplyDetail.vue'),
      props: ({ params, query }) => ({ ...params, ...query }),
      meta: {
        title: '申请信息',
      },
    },
  ],
};

export default account;
