
  import { computed, defineComponent, unref } from 'vue';
  import HeaderDropDown from './components/HeaderDropDown.vue';
  import { useUser } from '@/hooks/useUser';
  import { isProd } from '@/utils/const';
  export default defineComponent({
    name: 'AppLayoutHeader',
    setup() {
      const { userInfo } = useUser();
      const phone = computed(() => {
        return unref(userInfo)?.phone;
      });
      const email = computed(() => {
        return unref(userInfo)?.email;
      });
      return {
        phone,
        email,
      };
    },
    components: {
      HeaderDropDown,
    },
    data: () => ({ isProd }),
  });
