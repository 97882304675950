
  import { defineComponent, PropType, computed, toRaw } from 'vue';

  import { ActionItem } from '../types/tableAction';
  export default defineComponent({
    name: 'TableAction',
    props: {
      actions: {
        type: Array as PropType<ActionItem[]>,
        default: () => [],
      },
    },
    setup(props) {
      const getActions = computed(() => {
        return (toRaw(props.actions) || []).map((action) => {
          return {
            type: 'link',
            size: 'small',
            show: true,
            ...action,
          };
        });
      });
      return { getActions };
    },
  });
