
  import { defineComponent, computed } from 'vue';
  import WeAlert from '@/components/WeAlert/index.vue';
  import { appStore } from '@/store/modules/app';
  import { useApp } from '@/hooks/useApp';
  import { isEmptyData } from '@/utils/common';
  import { useRouter } from 'vue-router';
  export default defineComponent({
    name: 'AppLayoutContent',
    components: {
      WeAlert,
    },
    setup() {
      const { drawerConfig } = useApp();
      const router = useRouter();
      const alertConfig = computed(() => {
        return appStore.alertConfig;
      });
      const dialogConfig = computed(() => {
        return appStore.weDialog;
      });
      const getCacheComponents = computed(() => {
        const routeList = router.getRoutes();
        return routeList
          .filter((item) => {
            return (
              !isEmptyData(item.meta) && !isEmptyData(item.meta.ignoreKeepAlive) && item.meta.ignoreKeepAlive === false
            );
          })
          .map((mtem) => mtem.meta.componentName);
      });

      return {
        drawerConfig,
        alertConfig,
        dialogConfig,
        getCacheComponents,
      };
    },
  });
