import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { removeCookie } from './cookies';
import { isProd, is_in_qiankun } from './const';

const service = axios.create({
  baseURL: is_in_qiankun
    ? isProd
      ? 'https://asap.webeyeug.com.cn'
      : 'http://hw-test-opa.cd2d07431f1f249f49465b7cb40827b3f.cn-shenzhen.alicontainer.com'
    : process.env.VUE_APP_AXIOS_API,
  timeout: 60000,
  withCredentials: true,
});

/**
 * @description: 请求之前的拦截器
 */
const requestInterceptor = (config: AxiosRequestConfig) => {
  if (is_in_qiankun) {
    config.headers['AUTHORIZATION'] = localStorage.getItem('jwt_token');
  }

  return config;
};
/**
 * @description: 请求之前的拦截器错误处理
 */
const requestInterceptorCatch = (error: Error) => {
  Promise.reject(error);
};
/**
 * @description: 请求之后的拦截器
 */
const responseInterceptor = (response: AxiosResponse<any>) => {
  if (response.data.status === 700) {
    removeCookie('x-dev-token');
    const login_url = `${window.location.origin.replace('localhost', '127.0.0.1')}/login`;
    window.location.href = login_url;
    return Promise.resolve<any>(response.data.data || response.data);
  }
  if (response.data.status === 600) {
    location.reload();
    return Promise.resolve<any>(response.data.data || response.data);
  }
  if (
    response.data.success ||
    response.data.code === 0 ||
    response.data.error_code === 0 ||
    response.data.status === 200 ||
    response.status === 200
  ) {
    return Promise.resolve<any>(response.data.data || response.data);
  }

  const errMsg = response.data.msg || response.data.message;
  return Promise.reject<any>(new Error(errMsg));
};
/**
 * @description: 请求之后的拦截器错误处理
 */
const responseInterceptorCatch = (error: any) => {
  try {
    console.log(error);
  } catch (error) {
    throw new Error(error);
  }
  return Promise.reject(error);
};

service.interceptors.request.use(requestInterceptor, requestInterceptorCatch);
service.interceptors.response.use(responseInterceptor, responseInterceptorCatch);
export default service;
