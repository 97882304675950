import { getRowSpanFromTable, isEmptyData, transformComponentType } from '@/utils/common';
import keys from 'lodash-es/keys';
import orderBy from 'lodash-es/orderBy';
import uniq from 'lodash-es/uniq';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isBoolean from 'lodash/isBoolean';
import isFunction from 'lodash/isFunction';
import uniqueId from 'lodash/uniqueId';
import { computed, ComputedRef, nextTick, onMounted, reactive, ref, toRaw, unref, watch } from 'vue';
import { PAGE_SIZE, ROW_KEY } from '../const';
import type { PaginationProps } from '../types/pagination';
import type { BasicColumn, BasicTableProps, FetchParams, GetColumnsParams, SorterResult } from '../types/table';
interface ActionType {
  getPaginationRef: ComputedRef<boolean | PaginationProps>;
  setPagination: (info: Partial<PaginationProps>) => void;
  setLoading: (loading: boolean) => void;
  getFieldsValue: () => Recordable;
  getColumns: (opt?: GetColumnsParams) => BasicColumn[];
}

interface SearchState {
  sortInfo: Recordable;
  filterInfo: Record<string, string[]>;
}
export function useDataSource(
  propsRef: ComputedRef<BasicTableProps>,
  { getPaginationRef, setPagination, setLoading, getFieldsValue, getColumns }: ActionType,
  emit: EmitType
) {
  const searchState = reactive<SearchState>({
    sortInfo: {},
    filterInfo: {},
  });
  const cacheDataSourceRef = ref<Recordable[]>([]);
  const dataSourceRef = ref<Recordable[]>([]);
  const optionsRef = ref<Recordable>({});
  const totalRef = ref(0);
  watch(
    () => unref(propsRef).dataSource,
    (val) => {
      const { api, options } = unref(propsRef);
      if (!api && val) {
        dataSourceRef.value = val;
        cacheDataSourceRef.value = val;
        totalRef.value = val.length;
        if (options) {
          optionsRef.value = options;
        }
        getFilterDataSourceByFront();
      }
    },
    {
      immediate: true,
    }
  );
  function handleTableChange(
    pagination: PaginationProps,
    filters: Partial<Recordable<string[]>>,
    sorter: SorterResult
  ) {
    const { sortFn, filterFn, isWeApi } = unref(propsRef);
    setPagination(pagination);
    const params: Recordable = {};
    if (!isEmptyData(sorter) && isFunction(sortFn)) {
      let sortInfo: Recordable = {};
      if (isWeApi) {
        const { field, order } = sorter;
        if (order) {
          const sort = {};
          sort[field] = order === 'ascend' ? 'asc' : 'desc';
          sortInfo['sort'] = sort;
        }
      } else {
        sortInfo = sortFn!(sorter);
      }
      searchState.sortInfo = sortInfo;
      params.sortInfo = sortInfo;
    }
    if (!isEmptyData(filters) && isFunction(filterFn)) {
      const filterInfo = filterFn!(filters);
      searchState.filterInfo = filterInfo;
      params.filterInfo = filterInfo;
    }
    params.isFetchOptions = true;
    fetch(params);
  }
  const getAutoCreateKey = computed(() => {
    return unref(propsRef).autoCreateKey && !unref(propsRef).rowKey;
  });

  const getRowKey = computed(() => {
    const { rowKey } = unref(propsRef);
    return unref(getAutoCreateKey) ? ROW_KEY : rowKey;
  });

  const getDataSourceRef = computed(() => {
    let dataSource = unref(dataSourceRef);
    const { initItem } = unref(propsRef);
    if (!dataSource || dataSource.length === 0) {
      return [];
    }
    if (initItem) {
      dataSource.forEach((item) => {
        initItem(item);
      });
    }
    if (unref(getAutoCreateKey)) {
      const firstItem = dataSource[0];
      const lastItem = dataSource[dataSource.length - 1];
      if (firstItem && lastItem) {
        if (!firstItem[ROW_KEY] || !lastItem[ROW_KEY]) {
          const data = cloneDeep(unref(dataSourceRef));
          data.forEach((item) => {
            if (!item[ROW_KEY]) {
              item[ROW_KEY] = uniqueId();
            }
          });
          dataSource = data;
        }
      }
    }
    return dataSource;
  });

  async function updateTableData(index: number, key: string, value: any) {
    const record = dataSourceRef.value[index];
    if (record) {
      dataSourceRef.value[index][key] = value;
    }
    return dataSourceRef.value[index];
  }
  function getFilterOptionsByFront() {
    const configColumns = getColumns({ ignoreIndex: true, ignoreAction: true });
    const optionItems: string[] = [];
    configColumns.forEach((item) => {
      item.componentType === 'a-select' &&
        isEmptyData(item.componentProps?.options) &&
        !item.ignoreField &&
        optionItems.push(item.dataIndex as string);
    });
    const filterOptions: Recordable = {};
    optionItems.forEach((item) => {
      filterOptions[item] = uniq(
        cacheDataSourceRef.value.map((data) => {
          return data[item];
        })
      );
    });
    optionsRef.value = filterOptions;
  }
  // 前端过滤
  function getFilterDataSourceByFront() {
    const searchFields = getFieldsValue();
    const clone_cacheDataSource = cacheDataSourceRef.value;
    if (searchFields) {
      const filterFields = (Reflect.ownKeys(searchFields) as string[]).filter((item) => searchFields[item]);
      dataSourceRef.value = [];
      nextTick(() => {
        if (filterFields.length === 0) {
          dataSourceRef.value = clone_cacheDataSource;
        } else {
          dataSourceRef.value = clone_cacheDataSource.filter((item) => {
            return filterFields.every((key) => {
              if (Array.isArray(searchFields[key])) {
                if (!isEmptyData(searchFields[key][0]) && !isEmptyData(searchFields[key][1])) {
                  return searchFields[key][0] <= item[key] && searchFields[key][1] >= item[key];
                } else if (!isEmptyData(searchFields[key][0]) && isEmptyData(searchFields[key][1])) {
                  return searchFields[key][0] <= item[key];
                } else if (isEmptyData(searchFields[key][0]) && !isEmptyData(searchFields[key][1])) {
                  return item[key] <= searchFields[key][1];
                } else {
                  return true;
                }
              }
              if (typeof searchFields[key] === 'object') {
                if (searchFields[key].min !== '' && searchFields[key].max !== '') {
                  return searchFields[key].min <= item[key] && searchFields[key].max >= item[key];
                } else if (searchFields[key].min !== '' && searchFields[key].max === '') {
                  return searchFields[key].min <= item[key];
                } else if (searchFields[key].min === '' && searchFields[key].max !== '') {
                  return searchFields[key].max >= item[key];
                } else {
                  return true;
                }
              } else if (isBoolean(searchFields[key])) {
                return !isEmptyData(item[key]) && item[key] === searchFields[key];
              } else {
                return !isEmptyData(item[key]) && item[key].toUpperCase().includes(searchFields[key].toUpperCase());
              }
            });
          });
        }
        totalRef.value = dataSourceRef.value.length;
        getFilterOptionsByFront();
        getSortDataSourceByFront();
      });
    }
  }
  // 前端排序
  function getSortDataSourceByFront() {
    if (isEmptyData(searchState.sortInfo)) {
      return;
    }
    const opt = searchState.sortInfo;
    const sortArr = keys(searchState.sortInfo.sort);
    const getCurrentData = dataSourceRef.value;
    const sortedCurrentData = orderBy(getCurrentData, sortArr, [opt.sort[sortArr[0]]]);
    dataSourceRef.value = sortedCurrentData;
  }
  async function fetch(opt?: FetchParams) {
    const { api, searchInfo, fetchSetting, pagination, tabConfig, useSearchForm, isWeApi, columns } = unref(propsRef);
    if (!api || !isFunction(api)) {
      getFilterDataSourceByFront();
      emit('fetch-success', dataSourceRef.value);
      return;
    }
    setLoading(true);
    try {
      const { pageField, sizeField, listField, totalField, orderByField, orderField } = fetchSetting || {
        pageField: 'page',
        sizeField: 'rowsPerPage',
        listField: 'items',
        totalField: 'total',
        orderByField: 'order_by',
        orderField: 'order',
      };
      let pageParams: Recordable = {};
      const tabField: Recordable = {};
      const { current = 1, pageSize = PAGE_SIZE } = unref(getPaginationRef) as PaginationProps;
      if ((isBoolean(pagination) && !pagination) || isBoolean(getPaginationRef)) {
        pageParams = {};
      } else {
        pageParams[pageField] = (opt && opt.page) || current;
        pageParams[sizeField] = pageSize;
        pageParams[orderByField] = '';
        pageParams[orderField] = '';
      }
      if (tabConfig?.searchField) {
        tabField[tabConfig.searchField] = propsRef.value.activeKey;
      }
      const { sortInfo = {}, filterInfo } = searchState;
      let params: Recordable = {};
      params = {
        ...pageParams,
        ...tabField,
        ...searchInfo,
        ...(useSearchForm ? getFieldsValue() : {}),
        ...(opt?.searchInfo ?? {}),
        ...sortInfo,
        ...filterInfo,
        ...(opt?.sortInfo ?? {}),
        ...(opt?.filterInfo ?? {}),
      };
      if (isWeApi) {
        const configColumns = getColumns({ ignoreIndex: true, ignoreAction: true });
        const filters: { field: string; content: any; type: string }[] = [];
        const optionItems: string[] = [];
        if (useSearchForm) {
          const fieldsValueObj = getFieldsValue();
          if (isEmptyData(unref(optionsRef)) || opt?.isFetchOptions) {
            configColumns.forEach((item) => {
              item.componentType === 'a-select' &&
                isEmptyData(item.componentProps?.options) &&
                !item.ignoreField &&
                optionItems.push(item.dataIndex as string);
            });
          }
          Reflect.ownKeys(fieldsValueObj).forEach((item: string) => {
            if (!isEmptyData(fieldsValueObj[item])) {
              let matched_column: Recordable = {};
              const find_item = columns.find((c_item) => c_item.dataIndex === item);
              if (!isEmptyData(find_item)) {
                matched_column = find_item!;
              }
              if (!isEmptyData(matched_column)) {
                const type = matched_column.componentType as string;
                filters.push({
                  field: item,
                  content:
                    type === 'a-range-picker'
                      ? { min: fieldsValueObj[item][0], max: fieldsValueObj[item][1] }
                      : fieldsValueObj[item],
                  type: transformComponentType(type),
                });
              }
            }
          });
        }
        let fields: string[] = [];
        fields = configColumns.filter((ftem) => !ftem.ignoreField).map((item) => item.dataIndex) as string[];
        configColumns.filter((h) => h.colorField).forEach((h) => fields.push(h.colorField as string));
        params = {
          fields,
          ...tabField,
          ...searchInfo,
          ...(opt?.searchInfo ?? {}),
          page_params: {
            filters,
            options: optionItems,
            page: pageParams[pageField],
            page_size: pageParams[sizeField],

            ...sortInfo,
          },
        };
      }
      const res = await api(params);
      const isArrayResult = Array.isArray(res);
      let resultItems: Recordable[] = [];
      let resultTotal = 0;
      if (isWeApi) {
        resultItems = res.page_result ? res.page_result.data_list : res.data_list;
        resultTotal = res.page_result ? res.page_result.total_count : res.total_count;
        optionsRef.value = res.page_result ? res.page_result.options : res.options;
      } else {
        resultItems = isArrayResult ? res : get(res, listField);
        resultTotal = isArrayResult ? 0 : get(res, totalField);
      }
      // 假如数据变少，导致总页数变少并小于当前选中页码，通过getPaginationRef获取到的页码是不正确的，需获取正确的页码再次执行
      if (resultTotal) {
        const currentTotalPage = Math.ceil(resultTotal / pageSize);
        if (current > currentTotalPage) {
          setPagination({
            current: currentTotalPage,
          });
          fetch(opt);
        }
      }
      dataSourceRef.value = resultItems;
      totalRef.value = resultTotal;
      setPagination({
        total: resultTotal || 0,
      });
      if (opt && opt.page) {
        setPagination({
          current: opt.page || 1,
        });
      }
      emit('fetch-success', {
        items: resultItems,
        total: resultTotal,
      });
    } catch (error) {
      emit('fetch-error', error);
      dataSourceRef.value = [];
      setPagination({
        total: 0,
      });
    } finally {
      setLoading(false);
    }
  }
  async function fetchAllNoChange(opt?: any) {
    const { api, searchInfo, fetchSetting, pagination, tabConfig, useSearchForm, isWeApi, columns } = unref(propsRef);
    setLoading(true);
    try {
      const { pageField, sizeField, listField, orderByField, orderField } = fetchSetting || {
        pageField: 'page',
        sizeField: 'rowsPerPage',
        listField: 'items',
        orderByField: 'order_by',
        orderField: 'order',
      };
      let pageParams: Recordable = {};
      const tabField: Recordable = {};
      const { current = 1, pageSize = PAGE_SIZE } = unref(getPaginationRef) as PaginationProps;

      if ((isBoolean(pagination) && !pagination) || isBoolean(getPaginationRef)) {
        pageParams = {};
      } else {
        pageParams[pageField] = (opt && opt.page) || current;
        pageParams[sizeField] = pageSize;
        pageParams[orderByField] = '';
        pageParams[orderField] = '';
      }
      if (tabConfig?.searchField) {
        tabField[tabConfig.searchField] = propsRef.value.activeKey;
      }
      const { sortInfo = {}, filterInfo } = searchState;
      let params: Recordable = {};
      params = {
        ...pageParams,
        ...tabField,
        ...searchInfo,
        ...(useSearchForm ? getFieldsValue() : {}),
        ...(opt?.searchInfo ?? {}),
        ...sortInfo,
        ...filterInfo,
        ...(opt?.sortInfo ?? {}),
        ...(opt?.filterInfo ?? {}),
        page_params: {
          get_all: true,
        },
      };
      if (isWeApi) {
        const configColumns = getColumns({ ignoreIndex: true, ignoreAction: true });
        const filters: { field: string; content: any; type: string }[] = [];
        const fieldsValueObj = getFieldsValue();
        const optionItems: string[] = [];
        if (isEmptyData(unref(optionsRef)) || opt?.isFetchOptions) {
          configColumns.forEach((item) => {
            item.componentType === 'a-select' &&
              isEmptyData(item.componentProps?.options) &&
              !item.ignoreField &&
              optionItems.push(item.dataIndex as string);
          });
        }
        if (!isEmptyData(fieldsValueObj)) {
          Reflect.ownKeys(fieldsValueObj).forEach((item: string) => {
            if (fieldsValueObj[item]) {
              const matchedColumn = columns.filter((ctem) => ctem.dataIndex === item)[0];
              const type = matchedColumn.componentType as string;
              filters.push({
                field: item,
                content:
                  type === 'a-range-picker'
                    ? { min: fieldsValueObj[item][0], max: fieldsValueObj[item][1] }
                    : fieldsValueObj[item],
                type: transformComponentType(type),
              });
            }
          });
        }
        params = {
          fields: configColumns.filter((ftem) => !ftem.ignoreField).map((item) => item.dataIndex),
          ...tabField,
          ...searchInfo,
          ...(opt?.searchInfo ?? {}),
          page_params: {
            filters,
            options: optionItems,
            page: pageParams[pageField],
            page_size: pageParams[sizeField],
            get_all: true,
            ...sortInfo,
          },
        };
      }
      if (!api) {
        return [];
      }
      const res = await api(params);
      const isArrayResult = Array.isArray(res);
      let resultItems: Recordable[] = [];
      if (isWeApi) {
        resultItems = res.page_result ? res.page_result.data_list : res.data_list;
      } else {
        resultItems = isArrayResult ? res : get(res, listField);
      }
      return resultItems;
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }
  function getRowSpan(key, text, index, id?) {
    if (unref(propsRef).immediate) {
      return getRowSpanFromTable(unref(dataSourceRef), key, text, index, id);
    } else {
      const { current = 1, pageSize = PAGE_SIZE } = unref(getPaginationRef) as PaginationProps;
      const items = unref(dataSourceRef).slice((current - 1) * pageSize, current * pageSize);
      return getRowSpanFromTable(items, key, text, index, id);
    }
  }
  function setTableData<T = Recordable>(values: T[]) {
    dataSourceRef.value = values;
  }
  function getDataSource<T = Recordable>() {
    return toRaw(unref(getDataSourceRef)) as T[];
  }
  function getTotalCount() {
    return totalRef.value;
  }
  function getOptions() {
    return optionsRef.value;
  }
  async function reload(opt?: FetchParams) {
    optionsRef.value = {};
    await nextTick();
    await fetch(opt);
  }
  onMounted(() => {
    unref(propsRef).immediate && fetch();
  });

  return {
    getDataSourceRef,
    getDataSource,
    getTotalCount,
    getOptions,
    getRowKey,
    setTableData,
    getAutoCreateKey,
    fetch,
    fetchAllNoChange,
    reload,
    updateTableData,
    handleTableChange,
    getRowSpan,
  };
}
