import request from '@/utils/request';
/**
 * 获取OSS配置信息
 */
export function getOSSInfo() {
  return request({
    url: '/api/oss/sts_token',
    method: 'get',
  });
}
/**
 * 文件上传成功后，设置文件的读写权限
 * @param {}} data
 */
export function setOSSFileToRead(data: Recordable) {
  return request.post<null, Recordable>('/api/oss/public_file', data);
}
