
  import { commonStore } from '@/store/modules/commonLists';
  import { isEmptyData } from '@/utils/common';
  import { computed, defineComponent, unref } from 'vue';
  export default defineComponent({
    name: 'WeSelect',
    emits: ['update:value', 'change', 'returnObj'],
    props: {
      value: { type: [String, Number, Array] as PropType<any>, default: undefined },
      reversename: {
        type: Boolean,
        default: false,
      },
      options: {
        type: [String, Array] as PropType<any>,
        default: '',
      },
      placeholder: {
        type: String,
        default: '请选择',
      },
    },
    setup(props, { emit }) {
      const computedValue = computed({
        get: () => {
          if (props.value === -1 || props.value === '') {
            return undefined; // 空字符串会影响placehold的显示
          } else if (Array.isArray(props.value)) {
            return props.value;
          } else if (
            unref(getOptions).filter((p) => p.id === props.value || (props.reversename && p.name === props.value))
              .length === 0
          ) {
            return undefined;
          }
          return props.value;
        },
        set: (value) => {
          let vv: any = value;
          if (typeof vv == 'undefined') {
            vv = unref(optionIdType);
          }
          emit('update:value', vv);
          emit('change', vv);
        },
      });
      const getOptions = computed(() => {
        if (typeof props.options === 'string') {
          return commonStore[props.options] ?? [];
        }
        if ((props.options as Recordable[]).length > 0) {
          if (
            Object.prototype.hasOwnProperty.call(props.options[0], 'id') &&
            Object.prototype.hasOwnProperty.call(props.options[0], 'name')
          ) {
            return props.options;
          } else {
            return (props.options as Recordable[]).map((option) => ({
              id: option.value,
              name: option.text,
              disabled: isEmptyData(option.disabled) ? false : option.disabled,
            }));
          }
        }
        return [];
      });
      const optionIdType = computed(() => {
        if (unref(getOptions).length > 0) {
          if (typeof unref(getOptions)[0].id === 'string') {
            return '';
          } else if (typeof unref(getOptions)[0].id === 'number' && !props.reversename) {
            return undefined;
          } else {
            return '';
          }
        } else {
          return '';
        }
      });
      return {
        computedValue,
        getOptions,
        optionIdType,
      };
    },
    methods: {
      handleChange(index: number) {
        let temp;
        this.getOptions.forEach((item: any) => {
          if (item.id === index) {
            temp = item;
          }
        });
        this.$emit('returnObj', temp);
      },
    },
  });
