import { RouteRecordRaw } from 'vue-router';
import manage from './manage';
const route: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: '登录',
      ignoreKeepAlive: true,
    },
    component: () => import('@/views/login/index.vue'),
  },
  manage,
];
export default route;
