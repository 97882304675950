import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_we_dialog = _resolveComponent("we-dialog")!
  const _component_we_alert = _resolveComponent("we-alert")!
  const _component_we_drawer = _resolveComponent("we-drawer")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!

  return (_openBlock(), _createBlock(_component_a_layout_content, { class: "opa_app_content" }, {
    default: _withCtx(() => [
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component, route }) => [
          _createVNode(_Transition, null, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_KeepAlive, { include: _ctx.getCacheComponents }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                  key: route.fullPath
                }))
              ], 1032, ["include"]))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      }),
      _createVNode(_component_we_dialog, _normalizeProps(_guardReactiveProps({ ..._ctx.dialogConfig })), null, 16),
      _createVNode(_component_we_alert, _normalizeProps(_guardReactiveProps(_ctx.alertConfig)), null, 16),
      _createVNode(_component_we_drawer, _normalizeProps(_guardReactiveProps(_ctx.drawerConfig)), null, 16)
    ]),
    _: 1
  }))
}