
  import { defineComponent, ref, reactive } from 'vue';
  import { useRouter } from 'vue-router';
  import { useApp } from '@/hooks/useApp';
  import { appStore } from '@/store/modules/app';
  import { loginOut, quick_login, change_password } from '@/api/app';
  import { cacheStore } from '@/store/modules/cache';
  import { removeCookie, setCookie } from '@/utils/cookies';
  import { RuleObject } from 'ant-design-vue/lib/form/interface';
  export default defineComponent({
    name: 'HeaderDropDown',
    props: {
      userEmail: {
        type: String,
        default: '',
      },
      userPhone: {
        type: String,
        default: '',
      },
    },
    setup() {
      const passwordForm = ref<Nullable<FormActionType>>(null);
      const phone = ref('');
      const email = ref('');
      const passwordItem: any = ref({});
      const showChangePassword = ref(false);
      const showQuickLogin = ref(false);

      function validatePassword(_rule: RuleObject, value: string) {
        if (!value) {
          return Promise.reject('请再次输入密码');
        } else if (value !== passwordItem.value.newPassword) {
          return Promise.reject('密码内容不一致');
        } else {
          return Promise.resolve();
        }
      }

      const rules = reactive({
        oldPassword: [
          {
            required: true,
            type: 'string',
            message: '请输入密码',
            trigger: 'blur',
          },
        ],
        newPassword: [
          {
            required: true,
            type: 'string',
            message: '请输入密码',
            trigger: 'blur',
          },
        ],
        newPasswordConfirm: {
          required: true,
          type: 'string',
          trigger: 'blur',
          validator: validatePassword,
        },
      });

      const { $message } = useApp();
      const router = useRouter();

      return { showQuickLogin, showChangePassword, phone, email, passwordForm, passwordItem, $message, router, rules };
    },
    methods: {
      quick_login,
      menuClick({ key }: { key: string }) {
        switch (key) {
          case 'logout':
            this.handleLogout();
            break;
          case 'quick_login':
            this.showQuickLogin = true;
            break;
          case 'change_password':
            this.passwordForm?.clearValidate();
            this.passwordItem = {};
            this.showChangePassword = true;
            break;
          default:
            break;
        }
      },
      handleLogout() {
        removeCookie('x-dev-token');
        loginOut()
          .then(() => {
            this.$router.push({ name: 'Login' });
          })
          .catch(() => {
            this.$router.push({ name: 'Login' });
          })
          .finally(() => {
            cacheStore.SET_USER_INFO(null);
            appStore.SET_WE_DIALOG(null);
            appStore.SET_DRAWER_CONFIG(null);
            appStore.SET_WE_CHANGE(null);
            appStore.SET_ALERT_CONFIG(null);
          });
      },
      async quickLogin() {
        const result = await quick_login({ phone: this.phone, email: this.email });
        if (result.success || result.account_id) {
          cacheStore.SET_USER_INFO(result);
          setCookie('x-dev-token', result.session_token);
          window.location.reload();
        } else {
          throw new Error(result.msg);
        }
        this.showQuickLogin = false;
      },
      async changePasseord() {
        try {
          await this.passwordForm?.validate();
          await change_password({
            password: this.passwordItem.oldPassword,
            new_password: this.passwordItem.newPassword,
          }).then((res: any) => {
            if (res && res.code && res.code > 0) {
              throw new Error(res.msg);
            } else {
              this.$message.success('修改成功，请重新登录！');
              this.showChangePassword = false;
              this.handleLogout();
            }
          });
        } catch (err) {
          this.$message.error(err.toString());
        }
      },
    },
  });
