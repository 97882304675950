import request from '@/utils/request';
import { IUserInfo } from 'types/store';

export function loginOut() {
  return request.post<null>('/api/login/logout');
}
// 获取手机验证码
export function get_phone_code(data: { phone: string }) {
  return request.post<null>('/api/login/get_phone_code', data);
}
// 获取邮箱验证码
export function get_email_code(data: { email: string }) {
  return request.post<null>('/api/login/get_email_code', data);
}
// 验证码登录
export function login_by_code(data: { email?: string; phone?: string; code: string }) {
  return request.post<null, IUserInfo>('/api/login/login_by_code', data);
}
// 密码登录
export function login_by_password(data: { email?: string; phone?: string; code: string }) {
  return request.post<null, IUserInfo>('/api/login/login_by_password', data);
}
// 快捷登录
export function quick_login(data: { email?: string; phone?: string }) {
  return request.post<null, IUserInfo>('/api/login/quick_login', data);
}
// 设置密码
export function change_password(data: { password?: string; new_password: string }) {
  return request.post<null>('/api/login/change_password', data);
}
