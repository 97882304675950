import { Module, VuexModule, getModule, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import { IAppInfo, IUserInfo } from 'types/store';
@Module({
  dynamic: true,
  store,
  name: 'cache',
  preserveState: Boolean(localStorage.getItem('opa_app')),
})
export default class Cache extends VuexModule {
  public sidebarCollapsed = false;
  public appInfo: IAppInfo | null = null;
  public userInfo: IUserInfo | null = null;

  @Mutation
  SET_APPINFO(appInfo: IAppInfo | null): void {
    this.appInfo = appInfo;
  }
  @Mutation
  SET_USER_INFO(userInfo: IUserInfo | null): void {
    this.userInfo = userInfo;
  }
  @Mutation
  TOGGLE_SIDEBAR_COLLAPSED(sidebarCollapsed: boolean): void {
    this.sidebarCollapsed = sidebarCollapsed;
  }
}
export const cacheStore = getModule<Cache>(Cache);
