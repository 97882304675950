import { resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createBlock(_component_a_drawer, {
    class: "we_drawer_container",
    width: _ctx.width,
    keyboard: true,
    closable: false,
    destroyOnClose: true,
    mask: false,
    zIndex: 999,
    visible: _ctx.drawerVisible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.drawerVisible) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_Transition, {
        "enter-active-class": `animate__animated animate__zoomIn page-toggle-enter-active`,
        "leave-active-class": `animate__animated animate__zoomOut page-toggle-leave-active`
      }, {
        default: _withCtx(() => [
          (_ctx.componentName !== '')
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentName), _mergeProps({ key: 0 }, _ctx.$attrs, {
                key: _ctx.$attrs[_ctx.keyName]
              }), null, 16))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["width", "visible"]))
}