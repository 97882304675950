import { MenuItem } from 'types/config';
import { intersection } from 'lodash-es';
import manage from './manage';
export const menus: MenuItem[] = [...manage];

export const formatMenu = (data: MenuItem[]) => {
  const tempMenus: MenuItem[] = [];
  const FlatMenus = (menus: MenuItem[]) => {
    menus.forEach((item) => {
      tempMenus.push({
        ...item,
      });
      if (item.children) {
        FlatMenus(item.children);
      }
    });
  };
  FlatMenus(data);
  return tempMenus;
};

export const getFlatTitlePathMenus = (menus) => {
  const tempMenus: MenuItem[] = [];
  const FlatMenus = (menus: MenuItem[], parentTitle = '') => {
    menus.forEach((item) => {
      tempMenus.push({
        ...item,
        title: parentTitle !== '' ? `${parentTitle}-${item.title}` : `${item.title}`,
      });
      if (item.children) {
        const title = parentTitle !== '' ? `${parentTitle}-${item.title}` : `${item.title}`;
        FlatMenus(item.children, title);
      }
    });
  };
  FlatMenus(menus);
  return tempMenus;
};
export const getHasRoleFlatPathMenus = (roles: string[], isUseRole?) => {
  const formatMenuPath = (data: MenuItem[], parentPath = '/') => {
    return data
      .filter((item: any) => {
        return intersection(item.auth, roles).length > 0 || !isUseRole;
      })
      .map((mtem) => {
        const result = {
          ...mtem,
          path: `${parentPath}${mtem.path}`,
        };
        if (mtem.children) {
          result.children = formatMenuPath(mtem.children, `${parentPath}${mtem.path}/`);
        }
        return result;
      });
  };
  return formatMenuPath(menus);
};
