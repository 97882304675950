
  import { defineComponent, ref, watch } from 'vue';
  import { isDev } from '@/utils/const';
  import { basicProps } from './props';
  import { appStore } from '@/store/modules/app';
  export default defineComponent({
    name: 'WeAlert',
    props: basicProps,
    setup(props) {
      const show = ref(false);
      const ok = ref(true);
      const okEnabled = ref(true);
      watch(
        () => props.title,
        (newVal) => {
          if (newVal !== '') {
            show.value = true;
            ok.value = true;
            if (props.disableTimeout) {
              okEnabled.value = false;
              setTimeout(() => {
                okEnabled.value = true;
              }, props.disableTimeout);
            }
          } else {
            show.value = false;
          }
        },
        {
          deep: true,
        }
      );
      return {
        show,
        ok,
        okEnabled,
      };
    },
    computed: {
      isShowFooter() {
        if (typeof this.callback === 'undefined') {
          return false;
        }
        return true;
      },
    },
    methods: {
      alertConfirm() {
        if (!isDev) {
          this.ok = false;
        }
        if (this.callback) {
          this.callback();
        }
      },
      cancelFn() {
        if (typeof this.cancel === 'function') {
          this.cancel();
        } else {
          appStore.SET_ALERT_CONFIG(null);
        }
      },
      // clickItem(item) {
      //   if (this.alertTable!.clickItem) {
      //     this.alertTable!.clickItem(item);
      //   }
      // },
      closeDialog() {
        appStore.SET_ALERT_CONFIG(null);
      },
    },
  });
