
  import { defineComponent, ref, watchEffect, computed } from 'vue';
  import { useTableContext } from '../hooks/useTableContext';
  import { formatJson, formatNumberByNumeral, isEmptyData } from '@/utils/common';
  import { exportJson2Excel } from '@/utils/excel';
  import { BasicColumn } from '../types/table';
  import { isString, sumBy } from 'lodash-es';

  interface Options {
    label: string;
    value: string;
    fixed?: boolean | 'left' | 'right';
  }
  export default defineComponent({
    name: 'TableHeader',
    props: {
      moreFunc: {
        type: Array as PropType<Recordable[]>,
        default: () => [],
      },
      table_display_type: {
        type: String,
        default: 'table',
      },
      title: {
        type: String,
        default: '',
      },
    },
    emits: ['refresh', 'change_display_type'],
    setup() {
      const columnListRef = ref<ComponentRef>(null);
      const visible = ref(false);
      const table = useTableContext();
      const api = table.getProps.value.api;
      const plainOptions = ref<Options[]>([]);
      const checkedList = ref<string[]>([]);
      const reload = table.reload;
      const checkAll = ref(false);
      const showSelectedCount = computed<boolean>(() => {
        const rowSelection = table.getProps.value.rowSelection;
        if (isEmptyData(rowSelection)) {
          return false;
        }
        return true;
      });
      const show_sum = computed<boolean>(() => {
        const columns = getColumns().filter((item) => item.componentType === 'we-number-range');
        if (isEmptyData(columns)) {
          return false;
        }
        return true;
      });
      const totalItem = computed(() => {
        return table.getTotalCount();
      });
      const selectedCount = computed(() => {
        if (table) {
          return table.getSelectRows().length;
        } else {
          return 0;
        }
      });
      function getColumns(): (BasicColumn & { label: string; value: string })[] {
        const ret: BasicColumn & { label: string; value: string }[] = [];
        table.getColumns({ ignoreIndex: true, ignoreAction: true }).forEach((item) => {
          ret.push({
            label: (item.title as string) || (item.customTitle as string),
            value: (item.dataIndex || item.title) as string,
            ...item,
          });
        });
        return ret;
      }
      watchEffect(() => {
        const columns = table.getColumns();
        if (columns.length) {
          init();
        }
      });
      function init() {
        const columns = getColumns();
        const checkList = columns
          .map((item) => {
            if (item.defaultHidden) {
              return '';
            }
            return item.dataIndex || item.title;
          })
          .filter((item) => item) as string[];
        plainOptions.value = columns;
        checkedList.value = checkList;
        if (columns.length === checkList.length) {
          checkAll.value = true;
        }
      }
      function onChange(checkedList: string[]) {
        const columns = table.getColumns();
        columns.forEach((item) => {
          if (checkedList.includes(item.dataIndex as string) || !item.dataIndex || item.flag === 'ACTION') {
            item.defaultHidden = false;
          } else {
            item.defaultHidden = true;
          }
        });
        table.setColumns(columns);
      }
      async function exportExcel() {
        const columns = table.getViewColumns().filter((column) => column.dataIndex && column.flag !== 'ACTION');
        const selectedRows = table.getSelectRows();
        const tHeader = columns.map((item) => {
          return item.title || (item.customTitle as string);
        });
        const filterVal = columns.map((item) => {
          return item.dataIndex;
        });
        let tableData: any = [];
        if (selectedRows.length === 0) {
          if (api) {
            const resultItems = await table.fetchAllNoChange();
            tableData = resultItems;
          } else {
            tableData = table.getDataSource();
          }
        } else {
          tableData = selectedRows;
        }
        const data = formatJson(filterVal, tableData);
        exportJson2Excel(tHeader, data);
        table.clearSelectedRowKeys();
      }
      return {
        api,
        table,
        exportExcel,
        columnListRef,
        checkedList,
        plainOptions,
        onChange,
        getColumns,
        reload,
        selectedCount,
        show_sum,
        totalItem,
        showSelectedCount,
        checkAll,
        visible,
      };
    },
    methods: {
      isEmptyData,
      onCheckAllChange() {
        if (this.checkAll) {
          this.checkedList = this.plainOptions.map((item) => item.value);
        } else {
          this.checkedList = [];
        }
        this.onChange(this.checkedList);
      },
      handle_column_change(val) {
        this.$emit('change_display_type', 'chart', val);
      },
      refreshData() {
        if (this.api) {
          this.reload();
        } else {
          this.$emit('refresh');
        }
      },
      async sumData() {
        const columns = this.getColumns();
        const selectedRows = this.table.getSelectRows();
        const isWeApi = this.table.getProps.value.isWeApi;

        const numberItems: { title: string; dataIndex: string; value: number }[] =
          columns
            .filter((item) => item.componentType === 'we-number-range')
            .map((item) => ({
              title: item.title as string,
              dataIndex: item.dataIndex || (item.title as string),
              value: 0,
            })) || [];
        let tableData: any = [];
        if (selectedRows.length === 0) {
          if (isWeApi && this.api) {
            const resultItems = await this.table.fetchAllNoChange();
            tableData = resultItems;
          } else {
            tableData = this.table.getDataSource();
          }
        } else {
          tableData = selectedRows;
        }
        let showMsg = '';
        numberItems.forEach((item) => {
          if (tableData.some((s) => isString(s[item.dataIndex]))) {
            tableData.forEach((t) => (t[item.dataIndex] = Number(t[item.dataIndex])));
          }
          item.value = formatNumberByNumeral(Number(sumBy(tableData, item.dataIndex)), '0.00');
          showMsg += `<span style="display: block; width: max-content; text-align: right; margin-right: 5px;">${item.title}:${item.value} </span>`;
        });
        this.$showAlert('Sum of Searched Items: ' + numberItems.length, showMsg);
      },
    },
  });
