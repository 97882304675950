export const basicProps = {
  title: {
    type: String,
    default: '',
  },
  callback: {
    type: Function as PropType<(...arg: any[]) => any>,
    default: undefined,
  },
  cancel: {
    type: Function as PropType<(...arg: any[]) => any>,
  },
  okText: {
    type: String,
    default: undefined,
  },
  alertText: {
    type: String,
  },
  cancelText: {
    type: String,
    default: undefined,
  },
  maxWidth: {
    type: String,
    default: '600px',
  },
  disableTimeout: {
    type: Number,
    default: undefined,
  },
  alertTextStyle: {
    type: String,
    default: '',
  },
  alertTable: {
    type: Object,
    default: () => undefined,
  },
};
