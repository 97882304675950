import { saveAs } from 'file-saver';
import XLSX, { WorkBook, WorkSheet } from 'xlsx';
import { isEmptyData } from './common';

interface ICell {
  v: Date | number | boolean | string;
  t: string;
  z: string;
}

class Workbook implements WorkBook {
  SheetNames: string[] = [];
  Sheets: { [sheet: string]: WorkSheet } = {};
}

const datenum = (date: Date) => {
  return (+date - +new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
};

const sheetFromDataArray = (data: any) => {
  const ws: { [key: string]: any } = {};
  const range = {
    s: {
      c: 10000000,
      r: 10000000,
    },
    e: {
      c: 0,
      r: 0,
    },
  };
  for (let R = 0; R !== data.length; ++R) {
    for (let C = 0; C !== data[R].length; ++C) {
      if (range.s.r > R) range.s.r = R;
      if (range.s.c > C) range.s.c = C;
      if (range.e.r < R) range.e.r = R;
      if (range.e.c < C) range.e.c = C;
      const cell: ICell = {
        v: data[R][C],
        t: '',
        z: '',
      };
      if (cell.v == null) continue;
      const cellRef = XLSX.utils.encode_cell({
        c: C,
        r: R,
      });
      if (typeof cell.v === 'number') cell.t = 'n';
      else if (typeof cell.v === 'boolean') cell.t = 'b';
      else if (cell.v instanceof Date) {
        cell.t = 'n';
        cell.z = XLSX.SSF.get_table()[14];
        cell.v = datenum(cell.v);
      } else cell.t = 's';
      ws[cellRef] = cell;
    }
  }
  if (range.s.c < 10000000) {
    ws['!ref'] = XLSX.utils.encode_range(range);
  }
  return ws;
};

const s2ab = (s: string) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; ++i) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
};

export const exportJson2Excel = (
  header: string[],
  data: any,
  title?: string,
  filename = 'excel-list',
  multiHeader: string[][] = [],
  merges: any[] = [],
  autoWidth = true,
  bookType = 'xlsx'
) => {
  data = [...data];
  data.unshift(header);
  for (let i = multiHeader.length - 1; i > -1; i--) {
    data.unshift(multiHeader[i]);
  }
  if (title) {
    const title_item = new Array(header.length - 1).fill('');
    title_item.unshift(title);
    data.unshift(title_item);
  }
  const wsName = 'SheetJS';
  const wb = new Workbook();
  const ws = sheetFromDataArray(data);

  if (merges.length > 0) {
    if (!ws['!merges']) {
      ws['!merges'] = [];
    }
    merges.forEach((item) => {
      ws['!merges'].push(XLSX.utils.decode_range(item));
    });
  }
  if (title) {
    if (!ws['!merges']) {
      ws['!merges'] = [];
    }
    ws['!merges'].unshift({ s: { r: 0, c: 0 }, e: { r: 0, c: header.length } });
  }
  if (autoWidth) {
    // 设置worksheet每列的最大宽度
    const colWidth = data.map((row: any) =>
      row.map((val: any) => {
        // 先判断是否为 null/undefined
        if (val == null) {
          return {
            wch: 10,
          };
          // 再判断是否为中文
        } else if (val.toString().charCodeAt(0) > 255) {
          return {
            wch: val.toString().length * 2,
          };
        } else {
          return {
            wch: val.toString().length,
          };
        }
      })
    );
    // 以第一行为初始值
    const result = colWidth[0];
    for (let i = 1; i < colWidth.length; i++) {
      for (let j = 0; j < colWidth[i].length; j++) {
        if (result[j].wch < colWidth[i][j].wch) {
          result[j].wch = colWidth[i][j].wch;
        }
      }
    }
    ws['!cols'] = result;
  }

  // Add worksheet to workbook
  wb.SheetNames.push(wsName);
  wb.Sheets[wsName] = ws;

  const wbout = XLSX.write(wb, {
    bookType: bookType as any,
    bookSST: false,
    type: 'binary',
  });

  saveAs(
    new Blob([s2ab(wbout)], {
      type: 'application/octet-stream',
    }),
    `${filename}.${bookType}`
  );
};

export function downloadCsv(filename, rows) {
  const processRow = function (row) {
    let finalVal = '';
    for (let j = 0; j < row.length; j++) {
      let innerValue = isEmptyData(row[j]) ? '' : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) {
        result = '"' + result + '"';
      }
      if (j > 0) {
        finalVal += ',';
      }
      finalVal += result;
    }
    return finalVal + '\n';
  };

  let csvFile = '\uFEFF';
  for (let i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  if ((navigator as any).msSaveBlob) {
    // IE 10+
    (navigator as any).msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function sheet2blob(sheet, sheetName?, workbook?) {
  if (!workbook) {
    sheetName = sheetName || 'sheet1';
    workbook = {
      SheetNames: [sheetName],
      Sheets: {},
    };
    workbook.Sheets[sheetName] = sheet; // 生成excel的配置项
  }

  const wopts = {
    bookType: 'xlsx', // 要生成的文件类型
    bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
    type: 'binary',
  };
  const wbout = XLSX.write(workbook, wopts as any);
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    let view: any = [];
    view = new Uint8Array(buf);
    for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }
  const blob = new Blob([s2ab(wbout)], {
    type: 'application/octet-stream',
  }); // 字符串转ArrayBuffer
  return blob;
}

export function openDownloadDialog(url, saveName) {
  if (typeof url == 'object' && url instanceof Blob) {
    url = URL.createObjectURL(url); // 创建blob地址
  }
  const aLink = document.createElement('a');
  aLink.href = url;
  aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
  let event;
  if (window.MouseEvent) event = new MouseEvent('click');
  else {
    event = document.createEvent('MouseEvents');
    event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
  }
  aLink.dispatchEvent(event);
}
