/* eslint-disable camelcase */
import request from '@/utils/request';

// 获取申请列表
export function get_opa_apply_list(data) {
  return request.post<null, Recordable[]>('/api/service/get_opa_apply_list', data);
}

// 获取账户列表
export function get_opa_account_list(data) {
  return request.post<null, Recordable[] | Recordable>('/api/service/get_opa_account_list', data);
}

// 填写申请信息并提交
export function edit_opa_apply(data) {
  return request.post<null, Recordable>('/api/service/edit_opa_apply', data);
}

// 获取申请详情信息
export function get_opa_apply(data: { id: number }) {
  return request.post<null, Recordable>('/api/service/get_opa_apply', data);
}
// 提交充值申请
export function create_ugs_recharge_apply(data: Recordable) {
  return request.post<null, Recordable>('/api/service/create_ugs_recharge_apply', data);
}
// 提交充值列表
export function get_ugs_recharge_apply_list(data: Recordable) {
  return request.post<null, Recordable[]>('/api/service/get_ugs_recharge_apply_list', data);
}
// 获取充值详情
export function get_ugs_recharge_apply_item(data: Recordable) {
  return request.post<null, Recordable>('/api/service/get_ugs_recharge_apply_item', data);
}
// 获取平台字典
export function get_ugs_recharge_const() {
  return request.post<null, Recordable>('/api/service/get_ugs_recharge_const');
}
// 获取产品字典
export function get_product_list() {
  return request.post<null, Recordable>('/api/service/get_product_list');
}
