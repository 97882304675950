import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createSlots as _createSlots, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "wedialog_footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_we_select = _resolveComponent("we-select")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_checkbox_group = _resolveComponent("a-checkbox-group")!
  const _component_a_month_picker = _resolveComponent("a-month-picker")!
  const _component_a_time_picker = _resolveComponent("a-time-picker")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_we_import_excel = _resolveComponent("we-import-excel")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _directive_preventDoubleClick = _resolveDirective("preventDoubleClick")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.dialogVisible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dialogVisible) = $event)),
    centered: "",
    closable: false,
    maskClosable: "",
    afterClose: _ctx.afterClose,
    width: _ctx.width,
    wrapClassName: "we-dialog_container"
  }, {
    title: _withCtx(() => [
      _createElementVNode("span", null, _toDisplayString(_ctx.title), 1)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          (_ctx.btns.length > 0)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.btns, (item, index) => {
                return (_openBlock(), _createBlock(_component_a_button, {
                  key: index,
                  onClick: ($event: any) => (_ctx.btnClick(item)),
                  type: "primary"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.text), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            : _createCommentVNode("", true),
          (!_ctx.noCancel)
            ? (_openBlock(), _createBlock(_component_a_button, {
                key: 1,
                onClick: _ctx.cancelClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.cancelText ? _ctx.cancelText : '取消'), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (!_ctx.noOk)
            ? _withDirectives((_openBlock(), _createBlock(_component_a_button, {
                key: 2,
                type: "primary",
                loading: _ctx.iconLoading,
                onClick: _ctx.save
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.okText ? _ctx.okText : '保存'), 1)
                ]),
                _: 1
              }, 8, ["loading", "onClick"])), [
                [_directive_preventDoubleClick, { time: 1000, loading: _ctx.iconLoading }]
              ])
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        ref: "WeDialogForm",
        model: _ctx.formModel,
        labelAlign: _ctx.labelAlign,
        layout: _ctx.layout,
        labelCol: _ctx.layout === 'vertical' ? {} : _ctx.isString(_ctx.labelCol) ? { style: `width:${_ctx.labelCol}` } : _ctx.labelCol,
        class: "dialog_form",
        wrapperCol: 
        _ctx.layout === 'vertical' ? {} : _ctx.isString(_ctx.labelCol) ? { style: `max-width:calc(100% - ${_ctx.labelCol})` } : _ctx.wrapperCol
      
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedFormItemBlocks, (block, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              (block.type === 'tip')
                ? (_openBlock(), _createBlock(_component_a_row, {
                    key: 0,
                    style: { 'margin-bottom': '24px' }
                  }, {
                    default: _withCtx(() => [
                      (block.title)
                        ? (_openBlock(), _createBlock(_component_a_col, {
                            key: 0,
                            span: 24,
                            style: _normalizeStyle({
              ...{
                'background-color': '#f6f7fb',
                height: '32px',
                'line-height': '32px',
                'font-size': '16px',
                'padding-left': '24px',
              },
              ...block.titleStyle,
            }),
                            class: _normalizeClass(block.titleClass)
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(block.title), 1)
                            ]),
                            _: 2
                          }, 1032, ["style", "class"]))
                        : _createCommentVNode("", true),
                      (block.value)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            style: _normalizeStyle(block.style),
                            class: "tip-block",
                            innerHTML: block.value
                          }, null, 12, _hoisted_1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true),
              (block.type === 'input')
                ? (_openBlock(), _createBlock(_component_a_row, {
                    key: 1,
                    gutter: 24
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(block.value, (formItem, fIndex) => {
                        return (_openBlock(), _createElementBlock(_Fragment, { key: fIndex }, [
                          (formItem.showElement)
                            ? (_openBlock(), _createBlock(_component_a_col, {
                                key: 0,
                                span: formItem.width
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_form_item, {
                                    label: formItem.label,
                                    rules: formItem.showElement ? formItem.rules : [],
                                    name: formItem.result,
                                    labelAlign: formItem.labelAlign ? formItem.labelAlign : 'right',
                                    labelCol: 
                  _ctx.layout === 'vertical'
                    ? {}
                    : formItem.labelCol
                    ? _ctx.isString(formItem.labelCol)
                      ? { style: `width:${formItem.labelCol}` }
                      : formItem.labelCol
                    : _ctx.isString(_ctx.labelCol)
                    ? { style: `width:${_ctx.labelCol}` }
                    : _ctx.labelCol
                ,
                                    wrapperCol: 
                  _ctx.layout === 'vertical'
                    ? {}
                    : formItem.labelCol
                    ? _ctx.isString(formItem.labelCol)
                      ? { style: `max-width:calc(100% - ${formItem.labelCol})` }
                      : formItem.wrapperCol
                    : _ctx.isString(_ctx.labelCol)
                    ? { style: `max-width:calc(100% - ${_ctx.labelCol})` }
                    : _ctx.wrapperCol
                
                                  }, {
                                    default: _withCtx(() => [
                                      (formItem.type === 'text' || formItem.type === 'a-input')
                                        ? (_openBlock(), _createBlock(_component_a_input, {
                                            key: 0,
                                            value: _ctx.formModel[formItem.result],
                                            "onUpdate:value": ($event: any) => ((_ctx.formModel[formItem.result]) = $event),
                                            type: formItem.is_pwd ? 'password' : 'text',
                                            placeholder: formItem.placeholder,
                                            onChange: ($event: any) => (_ctx.inputChange(formItem)),
                                            disabled: !!formItem.elementDisabled,
                                            suffix: formItem.suffix
                                          }, _createSlots({ _: 2 }, [
                                            (formItem.prefixIcon)
                                              ? {
                                                  name: "prefix",
                                                  fn: _withCtx(() => [
                                                    _createVNode(_component_svg_icon, {
                                                      "icon-name": formItem.prefixIcon
                                                    }, null, 8, ["icon-name"])
                                                  ]),
                                                  key: "0"
                                                }
                                              : undefined,
                                            (formItem.suffixIcon && !formItem.suffix)
                                              ? {
                                                  name: "suffix",
                                                  fn: _withCtx(() => [
                                                    _createVNode(_component_svg_icon, {
                                                      "icon-name": formItem.suffixIcon
                                                    }, null, 8, ["icon-name"])
                                                  ]),
                                                  key: "1"
                                                }
                                              : undefined
                                          ]), 1032, ["value", "onUpdate:value", "type", "placeholder", "onChange", "disabled", "suffix"]))
                                        : _createCommentVNode("", true),
                                      (formItem.type === 'number')
                                        ? (_openBlock(), _createBlock(_component_a_input_number, _mergeProps({
                                            key: 1,
                                            value: _ctx.formModel[formItem.result],
                                            "onUpdate:value": ($event: any) => ((_ctx.formModel[formItem.result]) = $event),
                                            placeholder: formItem.placeholder,
                                            onChange: ($event: any) => (_ctx.inputChange(formItem)),
                                            disabled: !!formItem.elementDisabled
                                          }, _ctx.isEmptyData(formItem.componentProps) ? {} : formItem.componentProps), null, 16, ["value", "onUpdate:value", "placeholder", "onChange", "disabled"]))
                                        : _createCommentVNode("", true),
                                      (formItem.type === 'select' || formItem.type === 'auto-select')
                                        ? (_openBlock(), _createBlock(_component_we_select, {
                                            key: 2,
                                            value: _ctx.formModel[formItem.result],
                                            "onUpdate:value": ($event: any) => ((_ctx.formModel[formItem.result]) = $event),
                                            placeholder: formItem.placeholder,
                                            disabled: !!formItem.elementDisabled,
                                            options: formItem.items,
                                            mode: formItem.mode,
                                            reversename: formItem.reversename ? true : false,
                                            onChange: ($event: any) => (_ctx.inputChange(formItem))
                                          }, {
                                            default: _withCtx(() => [
                                              (formItem.appendIcon)
                                                ? (_openBlock(), _createBlock(_component_svg_icon, {
                                                    key: 0,
                                                    "icon-name": formItem.appendIcon,
                                                    onClick: formItem.appendClick
                                                  }, null, 8, ["icon-name", "onClick"]))
                                                : _createCommentVNode("", true)
                                            ]),
                                            _: 2
                                          }, 1032, ["value", "onUpdate:value", "placeholder", "disabled", "options", "mode", "reversename", "onChange"]))
                                        : _createCommentVNode("", true),
                                      (['date'].includes(formItem.type))
                                        ? (_openBlock(), _createBlock(_component_a_date_picker, {
                                            key: 3,
                                            value: _ctx.formModel[formItem.result],
                                            "onUpdate:value": ($event: any) => ((_ctx.formModel[formItem.result]) = $event),
                                            allowClear: "",
                                            disabled: !!formItem.elementDisabled,
                                            placeholder: formItem.placeholder,
                                            valueFormat: formItem.valueFormat ? formItem.valueFormat : 'YYYY-MM-DD'
                                          }, {
                                            suffixIcon: _withCtx(() => [
                                              (formItem.appendIcon)
                                                ? (_openBlock(), _createBlock(_component_svg_icon, {
                                                    key: 0,
                                                    "icon-name": formItem.appendIcon,
                                                    onClick: formItem.appendClick
                                                  }, null, 8, ["icon-name", "onClick"]))
                                                : _createCommentVNode("", true)
                                            ]),
                                            _: 2
                                          }, 1032, ["value", "onUpdate:value", "disabled", "placeholder", "valueFormat"]))
                                        : _createCommentVNode("", true),
                                      (['check'].includes(formItem.type))
                                        ? (_openBlock(), _createBlock(_component_a_checkbox_group, {
                                            key: 4,
                                            value: _ctx.formModel[formItem.result],
                                            "onUpdate:value": ($event: any) => ((_ctx.formModel[formItem.result]) = $event)
                                          }, {
                                            default: _withCtx(() => [
                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formItem.value, (item, index) => {
                                                return (_openBlock(), _createBlock(_component_a_checkbox, {
                                                  key: index,
                                                  value: item.value,
                                                  disabled: item.disabled,
                                                  style: _normalizeStyle({ 'min-width': item.width })
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(item.label), 1)
                                                  ]),
                                                  _: 2
                                                }, 1032, ["value", "disabled", "style"]))
                                              }), 128))
                                            ]),
                                            _: 2
                                          }, 1032, ["value", "onUpdate:value"]))
                                        : _createCommentVNode("", true),
                                      (['month'].includes(formItem.type))
                                        ? (_openBlock(), _createBlock(_component_a_month_picker, {
                                            key: 5,
                                            value: _ctx.formModel[formItem.result],
                                            "onUpdate:value": ($event: any) => ((_ctx.formModel[formItem.result]) = $event),
                                            allowClear: "",
                                            disabled: !!formItem.elementDisabled,
                                            placeholder: formItem.placeholder,
                                            valueFormat: !formItem.valueFormat ? 'YYYY-MM' : formItem.valueFormat
                                          }, {
                                            suffixIcon: _withCtx(() => [
                                              (formItem.suffixIcon)
                                                ? (_openBlock(), _createBlock(_component_svg_icon, {
                                                    key: 0,
                                                    "icon-name": formItem.suffixIcon
                                                  }, null, 8, ["icon-name"]))
                                                : _createCommentVNode("", true)
                                            ]),
                                            _: 2
                                          }, 1032, ["value", "onUpdate:value", "disabled", "placeholder", "valueFormat"]))
                                        : _createCommentVNode("", true),
                                      (formItem.type === 'date-hour')
                                        ? (_openBlock(), _createBlock(_component_a_time_picker, {
                                            key: 6,
                                            value: _ctx.formModel[formItem.result],
                                            "onUpdate:value": ($event: any) => ((_ctx.formModel[formItem.result]) = $event),
                                            placeholder: formItem.placeholder,
                                            disabled: !!formItem.elementDisabled,
                                            valueFormat: formItem.valueFormat ? formItem.valueFormat : 'YYYY-MM-DD HH:mm:ss',
                                            "minute-step": 30,
                                            format: "HH:mm",
                                            allowClear: ""
                                          }, {
                                            suffixIcon: _withCtx(() => [
                                              (formItem.suffixIcon)
                                                ? (_openBlock(), _createBlock(_component_svg_icon, {
                                                    key: 0,
                                                    "icon-name": formItem.suffixIcon
                                                  }, null, 8, ["icon-name"]))
                                                : _createCommentVNode("", true)
                                            ]),
                                            _: 2
                                          }, 1032, ["value", "onUpdate:value", "placeholder", "disabled", "valueFormat"]))
                                        : _createCommentVNode("", true),
                                      (['a-range-picker'].includes(formItem.type))
                                        ? (_openBlock(), _createBlock(_component_a_range_picker, {
                                            key: 7,
                                            value: _ctx.formModel[formItem.result],
                                            "onUpdate:value": ($event: any) => ((_ctx.formModel[formItem.result]) = $event),
                                            allowClear: "",
                                            disabled: !!formItem.elementDisabled,
                                            placeholder: formItem.placeholder,
                                            valueFormat: formItem.valueFormat ? formItem.valueFormat : 'YYYY-MM-DD'
                                          }, {
                                            suffixIcon: _withCtx(() => [
                                              (formItem.appendIcon)
                                                ? (_openBlock(), _createBlock(_component_svg_icon, {
                                                    key: 0,
                                                    "icon-name": formItem.appendIcon,
                                                    onClick: formItem.appendClick
                                                  }, null, 8, ["icon-name", "onClick"]))
                                                : _createCommentVNode("", true)
                                            ]),
                                            _: 2
                                          }, 1032, ["value", "onUpdate:value", "disabled", "placeholder", "valueFormat"]))
                                        : _createCommentVNode("", true),
                                      (formItem.type === 'radio')
                                        ? (_openBlock(), _createBlock(_component_a_radio_group, {
                                            key: 8,
                                            value: _ctx.formModel[formItem.result],
                                            "onUpdate:value": ($event: any) => ((_ctx.formModel[formItem.result]) = $event),
                                            disabled: !!formItem.elementDisabled,
                                            onChange: ($event: any) => (_ctx.inputChange(formItem))
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_a_row, { gutter: 24 }, {
                                                default: _withCtx(() => [
                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formItem.items, (item, index) => {
                                                    return (_openBlock(), _createBlock(_component_a_col, {
                                                      span: 12,
                                                      key: index
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_a_radio, {
                                                          value: item.value
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(item.text), 1)
                                                          ]),
                                                          _: 2
                                                        }, 1032, ["value"])
                                                      ]),
                                                      _: 2
                                                    }, 1024))
                                                  }), 128))
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ]),
                                            _: 2
                                          }, 1032, ["value", "onUpdate:value", "disabled", "onChange"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                  }, 1032, ["label", "rules", "name", "labelAlign", "labelCol", "wrapperCol"])
                                ]),
                                _: 2
                              }, 1032, ["span"]))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 128))
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true),
              (block.type === 'radio' && block.showElement)
                ? (_openBlock(), _createBlock(_component_a_row, { key: 2 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, {
                        span: block.width
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            label: block.title,
                            rules: block.showElement ? block.rules : [],
                            name: block.result,
                            labelAlign: block.labelAlign ? block.labelAlign : 'right'
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_radio_group, {
                                value: _ctx.formModel[block.result],
                                "onUpdate:value": ($event: any) => ((_ctx.formModel[block.result]) = $event)
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_row, {
                                    gutter: 24,
                                    class: "we-table_radio_group"
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(block.value, (item, index) => {
                                        return (_openBlock(), _createBlock(_component_a_col, {
                                          span: 8,
                                          key: index
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_a_radio, {
                                              value: item.value
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(item.label), 1)
                                              ]),
                                              _: 2
                                            }, 1032, ["value"])
                                          ]),
                                          _: 2
                                        }, 1024))
                                      }), 128))
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1032, ["value", "onUpdate:value"])
                            ]),
                            _: 2
                          }, 1032, ["label", "rules", "name", "labelAlign"])
                        ]),
                        _: 2
                      }, 1032, ["span"])
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true),
              (block.type === 'check' && block.showElement)
                ? (_openBlock(), _createBlock(_component_a_row, { key: 3 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, {
                        span: block.width
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            label: block.label,
                            rules: block.showElement ? block.rules : [],
                            name: block.result,
                            labelAlign: block.labelAlign ? block.labelAlign : 'right'
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_checkbox_group, {
                                value: _ctx.formModel[block.result],
                                "onUpdate:value": ($event: any) => ((_ctx.formModel[block.result]) = $event)
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(block.value, (item, index) => {
                                    return (_openBlock(), _createBlock(_component_a_checkbox, {
                                      key: index,
                                      value: item.value,
                                      style: _normalizeStyle({ 'min-width': item.width })
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.label), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value", "style"]))
                                  }), 128))
                                ]),
                                _: 2
                              }, 1032, ["value", "onUpdate:value"])
                            ]),
                            _: 2
                          }, 1032, ["label", "rules", "name", "labelAlign"])
                        ]),
                        _: 2
                      }, 1032, ["span"])
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true),
              (block.type === 'note' && block.showElement)
                ? (_openBlock(), _createBlock(_component_a_row, { key: 4 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, {
                        span: block.width
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            label: block.label,
                            rules: block.showElement ? block.rules : [],
                            name: block.result,
                            labelAlign: block.labelAlign ? block.labelAlign : 'right'
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_textarea, {
                                value: _ctx.formModel[block.result],
                                "onUpdate:value": ($event: any) => ((_ctx.formModel[block.result]) = $event),
                                placeholder: block.placeholder,
                                onChange: ($event: any) => (_ctx.inputChange(block)),
                                disabled: !!block.elementDisabled,
                                allowClear: "",
                                autosize: block.autosize
                              }, _createSlots({ _: 2 }, [
                                (block.prefixIcon)
                                  ? {
                                      name: "prefix",
                                      fn: _withCtx(() => [
                                        _createVNode(_component_svg_icon, {
                                          "icon-name": block.prefixIcon
                                        }, null, 8, ["icon-name"])
                                      ]),
                                      key: "0"
                                    }
                                  : undefined,
                                (block.suffixIcon)
                                  ? {
                                      name: "suffix",
                                      fn: _withCtx(() => [
                                        _createVNode(_component_svg_icon, {
                                          "icon-name": block.suffixIcon
                                        }, null, 8, ["icon-name"])
                                      ]),
                                      key: "1"
                                    }
                                  : undefined
                              ]), 1032, ["value", "onUpdate:value", "placeholder", "onChange", "disabled", "autosize"])
                            ]),
                            _: 2
                          }, 1032, ["label", "rules", "name", "labelAlign"])
                        ]),
                        _: 2
                      }, 1032, ["span"])
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true),
              (block.type === 'excel' && block.showElement)
                ? (_openBlock(), _createBlock(_component_a_row, { key: 5 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            label: block.label,
                            labelAlign: block.labelAlign ? block.labelAlign : 'right'
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_we_import_excel, {
                                value: _ctx.formModel[block.result],
                                "onUpdate:value": ($event: any) => ((_ctx.formModel[block.result]) = $event),
                                config: block.config
                              }, null, 8, ["value", "onUpdate:value", "config"])
                            ]),
                            _: 2
                          }, 1032, ["label", "labelAlign"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true),
              (block.type === 'localfile' && block.showElement)
                ? (_openBlock(), _createBlock(_component_a_row, { key: 6 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            label: block.label,
                            rules: block.showElement ? block.rules : [],
                            name: block.result,
                            labelAlign: block.labelAlign ? block.labelAlign : 'right'
                          }, null, 8, ["label", "rules", "name", "labelAlign"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]),
        _: 1
      }, 8, ["model", "labelAlign", "layout", "labelCol", "wrapperCol"])
    ]),
    _: 1
  }, 8, ["visible", "afterClose", "width"]))
}