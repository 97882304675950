import { MenuItem } from 'types/config';

const manage: MenuItem[] = [
  {
    path: 'index',
    title: '首页',
    icon: 'HomeOutlined',
  },
  {
    path: 'open-list',
    title: '开户记录',
    icon: 'WE_DocStampOutlined',
  },
  {
    path: 'recharge-list',
    title: '充值记录',
    icon: 'WE_CMPLogoOutlined',
  },
  {
    path: 'account-list',
    title: '账户列表',
    icon: 'WE_BookOutlined',
  },
];
export default manage;
