import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "headline" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 0,
  class: "wedialog_footer"
}
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_we_table = _resolveComponent("we-table")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("template", null, [
    _createVNode(_component_a_modal, {
      wrapClassName: "we-alert_container",
      visible: _ctx.show,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.show) = $event)),
      width: _ctx.maxWidth,
      maskClosable: "",
      closable: false,
      centered: "",
      afterClose: _ctx.closeDialog,
      "z-index": 1001
    }, {
      title: _withCtx(() => [
        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.title), 1)
      ]),
      footer: _withCtx(() => [
        (_ctx.isShowFooter)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_a_button, { onClick: _ctx.cancelFn }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.cancelText || '取消'), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_a_button, {
                disabled: !_ctx.okEnabled,
                loading: !_ctx.ok,
                type: "primary",
                onClick: _ctx.alertConfirm
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.okText || '确定'), 1)
                ]),
                _: 1
              }, 8, ["disabled", "loading", "onClick"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_4))
      ]),
      default: _withCtx(() => [
        (_ctx.alertText)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              style: _normalizeStyle([_ctx.alertTextStyle, {"padding":"24px 0"}]),
              innerHTML: _ctx.alertText
            }, null, 12, _hoisted_2))
          : _createCommentVNode("", true),
        (_ctx.alertTable && _ctx.alertTable.clickItems && _ctx.alertTable.clickItems.length > 0)
          ? (_openBlock(), _createBlock(_component_we_table, _mergeProps({ key: 1 }, _ctx.alertTable.config, {
              dataSource: _ctx.alertTable.items
            }), {
              name: _withCtx(({ text }) => [
                _createVNode(_component_a_button, { type: "link" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(text), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }, 16, ["dataSource"]))
          : _createCommentVNode("", true),
        (_ctx.alertTable && !_ctx.alertTable.clickItems)
          ? (_openBlock(), _createBlock(_component_we_table, _mergeProps({ key: 2 }, _ctx.alertTable.config, {
              dataSource: _ctx.alertTable.items
            }), _createSlots({ _: 2 }, [
              (_ctx.alertTable.moreFunc)
                ? {
                    name: "headerRightIcon",
                    fn: _withCtx(() => [
                      _createVNode(_component_a_dropdown, null, {
                        overlay: _withCtx(() => [
                          _createVNode(_component_a_menu, null, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alertTable.moreFunc, (item) => {
                                return (_openBlock(), _createBlock(_component_a_menu_item, {
                                  onClick: item.click,
                                  key: item.title
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.title), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]))
                              }), 128))
                            ]),
                            _: 1
                          })
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_button, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_svg_icon, { "icon-name": "MoreOutlined" })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1040, ["dataSource"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible", "width", "afterClose"])
  ]))
}