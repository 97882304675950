import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getActions, (action, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: `${index}-${action.label}`
      }, [
        (action.show)
          ? (_openBlock(), _createBlock(_component_a_button, _mergeProps({ key: 0 }, action, {
              class: ['column_button_' + action.color, "table_action_btn"]
            }), {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(action.label), 1)
              ]),
              _: 2
            }, 1040, ["class"]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}