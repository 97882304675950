import { getHasRoleFlatPathMenus } from '@/router/menu';
import { cacheStore } from '@/store/modules/cache';
import { MenuItem } from 'types/config';
import { IUserInfo } from 'types/store';
import { computed } from 'vue';

export function useUser() {
  const MenusByRoles = computed<MenuItem[]>(() => {
    return getHasRoleFlatPathMenus([], false);
  });
  const userInfo = computed(() => {
    return cacheStore.userInfo as IUserInfo;
  });
  return {
    MenusByRoles,
    userInfo,
  };
}
