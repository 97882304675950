import { ref, onUnmounted, unref } from 'vue';
import type { FormActionType } from '../types/form';
import pickBy from 'lodash-es/pickBy';
import { isEmptyData } from '@/utils/common';
export type RegisterFn = (formInstance: FormActionType) => void;

export type UseFormReturnType = [RegisterFn, FormActionType];
export function useTableForm(): UseFormReturnType {
  const TableSearchFormElRef = ref<Nullable<FormActionType>>(null);
  const loadedRef = ref<Nullable<boolean>>(false);

  function register(instance: FormActionType) {
    onUnmounted(() => {
      TableSearchFormElRef.value = null;
      loadedRef.value = null;
    });
    if (unref(loadedRef) && instance === unref(TableSearchFormElRef)) return;

    TableSearchFormElRef.value = instance;
    loadedRef.value = true;
  }
  const methods: FormActionType = {
    getFieldsValue: <T>() => {
      return pickBy(unref(TableSearchFormElRef)?.getFieldsValue(), (value) => !isEmptyData(value)) as T;
    },
    set_search_item_value: (prop: string, val: any) => {
      return unref(TableSearchFormElRef)?.set_search_item_value(prop, val);
    },
  };

  return [register, methods];
}
