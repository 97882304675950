import type { Router } from 'vue-router';
import NProgress from 'nprogress';
import { appStore } from '@/store/modules/app';
import { getCookie } from '@/utils/cookies';
import { cacheStore } from '@/store/modules/cache';

export function createPermissionGuard(router: Router) {
  router.beforeEach(async (to, _from, next) => {
    NProgress.start();
    if (getCookie('x-dev-token')) {
      if (to.path === '/login') {
        next({ path: '/' });
      } else {
        next();
      }
    } else {
      cacheStore.SET_USER_INFO(null);
      if (to.path === '/login') {
        next();
      } else {
        next({ path: '/login' });
      }
    }
    NProgress.done();
  });
  router.afterEach((to) => {
    resetApp();
    if (to.meta.title) {
      document.title = `开户平台-${to.meta.title}`;
    } else {
      document.title = `开户平台`;
    }
    NProgress.done();
  });
  const resetApp = () => {
    appStore.SET_DRAWER_CONFIG(null);
    appStore.SET_ALERT_CONFIG(null);
    appStore.SET_WE_DIALOG(null);
  };
}
