
  import { defineComponent, reactive, ref, unref, computed } from 'vue';
  import { DialogFormItem } from './types';
  import { appStore } from '@/store/modules/app';
  import cloneDeep from 'lodash/cloneDeep';
  import isEqual from 'lodash-es/isEqual';
  import { basicProps } from './props';
  import isString from 'lodash-es/isString';
  import WeImportExcel from '@/components/WeImportExcel/index.vue';
  import { isEmptyData } from '@/utils/common';
  import pick from 'lodash-es/pick';
  import { isDev } from '@/utils/const';

  export default defineComponent({
    name: 'WeDialog',
    props: basicProps,
    components: {
      WeImportExcel,
    },
    data: () => ({
      ok: true,
    }),
    setup(props) {
      const WeDialogForm = ref<Nullable<FormActionType>>(null);
      const iconLoading = ref<boolean>(false);
      const formItemblocks = ref<Recordable>([]);
      const computedFormItemBlocks = computed(() => {
        return formItemblocks.value;
      });
      const formModel = reactive<Recordable>({});
      const getInit = function (formItem: DialogFormItem) {
        const valueField = formItem.resultValueFiled ? formItem.resultValueFiled : formItem.result;
        if (formItem.init !== undefined) {
          return formItem.init;
        }
        if (props.init && !isEmptyData(props.init[valueField as string])) {
          return props.init[valueField as string];
        }
        if (formItem.mode === 'multiple' || formItem.mode === 'tag') {
          return [];
        }
        if (formItem.type === 'a-range-picker') {
          return [];
        }
        return null;
      };
      const changeInputDisplay = function () {
        const temp = cloneDeep(formItemblocks.value);
        temp.forEach((block) => {
          if (block.type === 'input') {
            block.value.forEach((item) => initInputDisplay(item));
          } else {
            initInputDisplay(block);
          }
        });
        formItemblocks.value = temp;
      };
      const initInputDisplay = function (formItem: DialogFormItem) {
        if (formItem.show) {
          formItem.showElement = formItem.show(unref(formModel));
        } else if (isEmptyData(formItem.showElement)) {
          formItem.showElement = true;
        }
        if (typeof formItem.disabled === 'function') {
          formItem.elementDisabled = formItem.disabled(unref(formModel));
        } else {
          formItem.elementDisabled = formItem.disabled;
        }
        if (typeof formItem.mode === 'function') {
          formItem.mode = formItem.mode(unref(formModel));
        }
      };
      const initBlocks = () => {
        const config = cloneDeep(props.blocks);
        config?.forEach((block) => {
          if (block.type === 'json') {
            block.type = 'tip';
            block.value = JSON.stringify(block.value, null, 2);
            block.style =
              'white-space: pre-wrap; font-family: monospace; height: 260px; overflow: auto; border: solid 1px #ddd; padding: 5px;';
          }
        });
        config?.forEach((block: DialogFormItem) => {
          if (block.type === 'input') {
            block.value.forEach((input) => {
              formModel[input.result] = getInit(input);
              if (isEmptyData(input.width)) {
                input.width = 24;
              }
            });
          } else if (block.type === 'radio') {
            let itemInit = null;
            block.value.forEach((radio) => {
              if (radio.itemInit) {
                itemInit = radio.value;
              }
            });
            const blockInit = typeof block.init !== 'undefined' ? block.init : null;
            const configInit = props.init ? props.init[block.result as string] : null;
            formModel[block.result as string] = itemInit || blockInit || configInit;
          } else if (block.type === 'check') {
            let init: any = [];
            block.value.forEach((check) => {
              if (check.init) {
                init.push(check.value);
              }
              check.width = check.width ? check.width : '200px';
              check.style = `margin: 5px 0 -15px 0 !important; width: ${check.width};`;
            });
            init = typeof block.init != 'undefined' ? block.init : init;
            if (block.cleanInit) {
              const cleanedInit: any = [];
              init.forEach((i) => {
                let keep = false;
                block.value.forEach((c) => {
                  if (isEqual(i, c.value)) {
                    keep = true;
                  }
                });
                if (keep) {
                  cleanedInit.push(i);
                }
              });
              init = cleanedInit;
            }
            formModel[block.result as string] = props.init ? props.init[block.result as string] : init;
          } else if (block.type === 'note') {
            formModel[block.result as string] = getInit(block);
          } else if (block.type === 'excel') {
            const init = block.init || block.init === '' ? block.init : null;
            formModel[block.result as string] = props.init ? props.init[block.result as string] : init;
          } else if (block.type === 'localfile') {
            const init = block.init || block.init === '' ? block.init : null;
            formModel[block.result as string] = props.init ? props.init[block.result as string] : init;
          }
          if (isEmptyData(block.width) && !['input', 'note'].includes(block.type!)) {
            block.width = 24;
          }
        });
        formItemblocks.value = config!;
        changeInputDisplay();
      };
      const dialogVisible = ref(false);
      return {
        initBlocks,
        computedFormItemBlocks,
        dialogVisible,
        WeDialogForm,
        formItemblocks,
        formModel,
        changeInputDisplay,
        iconLoading,
      };
    },
    methods: {
      isString,
      isEmptyData,
      inputChange(formItem: DialogFormItem) {
        if (formItem.change) {
          formItem.change(this.formModel, formItem, this.formItemblocks, this);
        }
      },
      btnClick(btn) {
        if (btn.callback) {
          btn.callback(this);
        }
      },
      cancelClick() {
        if (typeof this.cancelStop === 'function') {
          this.cancelStop(this.formModel, this);
        } else if (typeof this.cancel === 'function') {
          this.cancel(this.formModel, this);
          appStore.SET_WE_DIALOG(null);
        } else {
          appStore.SET_WE_DIALOG(null);
        }
      },
      afterClose() {
        Reflect.ownKeys(this.formModel).forEach((key: string) => {
          delete this.formModel[key];
        });
        this.formItemblocks = [];
        this.WeDialogForm?.clearValidate();
        if (typeof this.cancelStop === 'function') {
          this.cancelStop(this.formModel, this);
        } else if (typeof this.cancel === 'function') {
          // this.cancel(this.formModel, this);
          appStore.SET_WE_DIALOG(null);
        } else {
          appStore.SET_WE_DIALOG(null);
        }
      },
      async save() {
        await this.WeDialogForm?.validate();
        if (!isDev) {
          this.iconLoading = true;
        }
        const save_items: any[] = [];
        this.formItemblocks.forEach((i: Recordable) => {
          if (isEmptyData(i.value)) {
            if (i.showElement) {
              save_items.push(i.result);
            }
          } else if (i.type === 'input') {
            i.value.forEach((v) => {
              if (v.showElement) {
                save_items.push(v.result);
              }
            });
          }
        });
        this.callback(pick(this.formModel, save_items), this);
      },
    },
    watch: {
      formModel: {
        handler(val) {
          this.changeInputDisplay();
          if (this.change) {
            this.change(val);
          }
        },
        deep: true,
      },
      title: {
        handler(val) {
          if (val) {
            this.dialogVisible = true;
            this.initBlocks();
          } else {
            this.dialogVisible = false;
          }
          this.iconLoading = false;
        },
        immediate: true,
      },
    },
  });
