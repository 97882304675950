import type { Directive, App } from 'vue';

const ActiveDirective: Directive = {
  mounted(el, binding) {
    const color = binding.value || '#7a1180';
    el.addEventListener('click', () => {
      el.style.setProperty('color', color, 'important');
      console.log(el.style.color);
    });
  },
};

export function setupActiveDirective(app: App) {
  app.directive('active', ActiveDirective);
}

export default ActiveDirective;
