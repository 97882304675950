
  import { defineComponent, ref, computed, unref, provide } from 'vue';

  import { useResizeObserver } from '@vueuse/core';
  import { appStore } from '@/store/modules/app';
  import { useRoute } from 'vue-router';
  import { cacheStore } from '@/store/modules/cache';
  import { isEmptyData } from '@/utils/common';
  import { is_in_qiankun } from '@/utils/const';

  export default defineComponent({
    name: 'PageDetailWrapper',
    props: {
      PageDetailWrapperClass: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
      titleType: {
        type: String as PropType<'text' | 'breadcrumb' | 'slot'>,
        default: 'text',
      },
      collapsed: {
        type: Boolean,
        default: false,
      },
      dropdonwMenus: {
        type: Array as PropType<{ label: string; onClick: Fn }[]>,
        default: () => {
          return [];
        },
      },
      buttons: {
        type: Array,
        default: () => {
          return [];
        },
      },
      anchors: {
        type: Array,
        default: () => {
          return [];
        },
      },
      closeFn: {
        type: Function,
        default: undefined,
      },
      // sophon 预览功能需要隐藏头部
      showHeader: {
        type: Boolean,
        default: true,
      },
    },
    setup(props) {
      const route = useRoute();
      const computedTitle = computed<String>(() => {
        if (props.title !== '') {
          return props.title;
        } else if (props.titleType === 'text') {
          if (route.meta.title) {
            return route.meta.title as string;
          }
        }
        return '';
      });
      const pageDetailWrapperEL = ref<HTMLDivElement>();
      const pagebackup = ref<any>(null);
      const refHeight = ref('');
      const refWidth = ref('');
      const showClose = ref(true);
      provide('page-detail-wrapper-width', refWidth);
      const redirectHref = computed(() => {
        if (appStore.drawerConfig?.href) {
          return appStore.drawerConfig?.href;
        } else {
          return '';
        }
      });
      const affixisshow = computed(() => {
        if (unref(pagebackup) && unref(pagebackup).visible && props.anchors.length !== 0) {
          return true;
        } else {
          return false;
        }
      });
      useResizeObserver(pageDetailWrapperEL, (entries) => {
        const entry = entries[0];
        refWidth.value = entry.contentRect.width + 'px';
        if (entry.target.parentElement?.className.includes('ant-drawer-body')) {
          showClose.value = true;
          refHeight.value = '100vh';
        } else {
          showClose.value = false;
          refHeight.value = 'calc(100vh - 64px)';
        }
      });
      return {
        computedTitle,
        showClose,
        redirectHref,
        refHeight,
        affixisshow,
        pagebackup,
        pageDetailWrapperEL,
        refWidth,
      };
    },
    mounted() {
      if (this.collapsed) {
        this.$nextTick(() => {
          cacheStore.TOGGLE_SIDEBAR_COLLAPSED(!!this.collapsed);
        });
        if (is_in_qiankun) {
          this.$setGlobalState({
            is_collapsed: !!this.collapsed,
          });
        }
      }
    },
    methods: {
      getTarget() {
        return () => document.getElementById('page_detail_wrapper');
      },
      scrollIntoView(id: string) {
        document.getElementById(id)?.scrollIntoView({ block: 'center' });
      },
      async EnlargePageDetail() {
        window.open(this.redirectHref, '_blank');
        await this.closeDrawer();
        this.$nextTick(() => {
          cacheStore.TOGGLE_SIDEBAR_COLLAPSED(true);
          if (is_in_qiankun) {
            this.$setGlobalState({
              is_collapsed: true,
            });
          }
        });
      },
      closeDrawer() {
        if (this.closeFn) {
          this.closeFn();
          return;
        }
        const reloadList = appStore.drawerConfig?.reloadList;
        !isEmptyData(reloadList) && reloadList();
        appStore.SET_DRAWER_CONFIG(null);
      },
    },
  });
