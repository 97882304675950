import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, vShow as _vShow, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spin = _resolveComponent("Spin")!

  return _withDirectives((_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["full-loading", { absolute: _ctx.absolute }]),
    style: _normalizeStyle(_ctx.getStyle)
  }, [
    _createVNode(_component_Spin, _mergeProps(_ctx.$attrs, {
      tip: _ctx.tip,
      size: _ctx.size,
      spinning: _ctx.loading
    }), null, 16, ["tip", "size", "spinning"])
  ], 6)), [
    [_vShow, _ctx.loading]
  ])
}