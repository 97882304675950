import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators';
import store from '@/store';
import { SelectOption } from 'types/store';
import { getReginList, getTimeZoneList } from '@/api/commonList';
import { get_ugs_recharge_const } from '@/api/apply';
export interface ICommonListsState {
  platform: SelectOption[]; // 媒体平台
  region: SelectOption[]; // 主体区域
}

@Module({ dynamic: true, store, name: 'commonLists' })
class CommonLists extends VuexModule implements ICommonListsState {
  public platform: SelectOption[] = [];
  public region: SelectOption[] = [];
  public timezone: SelectOption[] = [];

  @Mutation
  SET_PLATFORM(platform: SelectOption[]) {
    this.platform = platform;
  }
  @Mutation
  SET_REGION(region: SelectOption[]) {
    this.region = region;
  }
  @Mutation
  SET_TIMEZONE(timezone: SelectOption[]) {
    this.timezone = timezone;
  }

  @Action({ commit: 'SET_PLATFORM' })
  public async getPlatform() {
    try {
      const { items } = { items: [] };
      return items;
    } catch (err) {
      console.error(err);
    }
  }
  @Action
  public async getUgsRechargeConst() {
    try {
      const { platform_show_name_map } = await get_ugs_recharge_const();
      this.SET_PLATFORM(platform_show_name_map);
    } catch (err) {
      console.error(err);
    }
  }
  @Action({ commit: 'SET_REGION' })
  public async getRegion() {
    try {
      const items = await getReginList();
      return items;
    } catch (err) {
      console.error(err);
    }
  }
  @Action({ commit: 'SET_TIMEZONE' })
  public async getTimeZone() {
    try {
      const items = await getTimeZoneList();
      return items.map((item) => ({ id: item, name: item }));
    } catch (err) {
      console.error(err);
    }
  }
}

export const commonStore = getModule(CommonLists);
export function transateCommonList(target: keyof ICommonListsState, value: string | number) {
  const list = commonStore[target];
  if (!list) {
    return '-';
  }
  const obj = list.find((item) => item.id === value);
  if (!obj) {
    return '-';
  }
  return obj.name;
}
