import { BasicTableProps } from '@/components/WeTable';
import { cloneDeep, isEmpty, isNil, sum, isEqual } from 'lodash-es';
import dayjs from 'dayjs';
import numeral from 'numeral';
import { SelectOption } from 'types/store';
import { unref } from 'vue';
import { isLark, isPc } from './const';

export function getDynamicProps<T, U>(props: T): Partial<U> {
  const ret: Recordable = {};
  Object.keys(props).map((key) => {
    ret[key] = unref((props as Recordable)[key]);
  });
  return ret as Partial<U>;
}
export const formatJson = (filterKeys: any, jsonData: any) =>
  jsonData.map((data: any) =>
    filterKeys.map((key: string) => {
      if (Array.isArray(data[key])) {
        return data[key].join('、');
      } else {
        return data[key];
      }
    })
  );
export function transformComponentType(type: string) {
  switch (type) {
    case 'a-time-picker':
    case 'a-date-picker':
    case 'a-input':
      return 'text';
    case 'we-number-range':
      return 'number';
    case 'a-range-picker':
      return 'date';
    case 'a-select':
    case 'we-select':
      return 'select';
    default:
      return 'text';
  }
}
export function copyObjectToReactive(refObj: any, data: Recordable) {
  console.log(refObj);
  Reflect.ownKeys(data).forEach((item: string) => {
    if (typeof data[item] === 'object') {
      refObj[item] = cloneDeep(data[item]);
    } else {
      refObj[item] = data[item];
    }
  });
}
export function getModelItems(model: any, keepZero = false) {
  const modelList: { name: any; text: any; id: any; value: any }[] = [];
  Object.keys(model).forEach((key) => {
    const id = parseInt(key, 10);
    if (id || (id === 0 && keepZero)) {
      modelList.push({
        name: model[key],
        text: model[key],
        id: id,
        value: id,
      });
    }
  });
  return modelList;
}
export const findDialogInput = (blocks, result_name): Recordable => {
  let entityInput = {};
  blocks.forEach((b) => {
    if (b.type === 'input') {
      b.value.forEach((i) => {
        if (i.result === result_name) {
          entityInput = i;
        }
      });
    }
  });

  return entityInput;
};
export const copyValue = (realValue: string) => {
  const input = document.getElementById('input')! as any; // 承载复制内容
  input.value = realValue; // 修改文本框的内容
  input.select(); // 选中文本
  document.execCommand('copy'); // 执行浏览器复制命令
};
export function AgentType(type: string) {
  return navigator.userAgent.toLowerCase().includes(type);
}
export function getStatusText(val: number) {
  switch (val) {
    case 1000:
    case 1100:
    case 1150:
    case 1200:
      return '评估中';
    case 1300:
    case 1500:
      return '接入中';
    case 1600:
      return '已接入';
    case 3000:
    case 4000:
      return '下线';
    case 4100:
    case 4150:
    case 4200:
      return '评估未通过';
    case 4300:
    case 4500:
      return '接入不成功';
    default:
      return '-';
  }
}
export function isImage(val: string) {
  const imageTypes = [
    'jpg',
    'bmp',
    'png',
    'tif',
    'gif',
    'pcx',
    'tga',
    'exif',
    'fpx',
    'svg',
    'psd',
    'cdr',
    'pcd',
    'dxf',
    'ufo',
    'eps',
    'ai',
    'raw',
    'wmf',
    'webp',
    'avif',
  ];
  return imageTypes.includes(val.toLowerCase());
}
export function isVideo(val: string) {
  const videoTypes = [
    'wmv',
    'mp4',
    'asf',
    'asx',
    'rm',
    'rmvb',
    'mpg',
    'mpeg',
    'mpe',
    '3gp',
    'mov',
    'mp4',
    'm4v',
    'avi',
    'dat',
    'mkv',
    'flv',
    'vob',
  ];
  return videoTypes.includes(val.toLowerCase());
}
export function getFileType(filename: string) {
  const index = filename.lastIndexOf('.');
  return filename.substring(index + 1, filename.length);
}
export function mediaType(val: string) {
  if (isImage(val)) {
    return 'img';
  } else if (isVideo(val)) {
    return 'video';
  } else {
    return val;
  }
}

/**
 * 用于表的有些行因数据相同而需要合并单元格
 * items 数据
 * key 键
 * text 值
 * index 当前索引
 * id 额外的分类主键，如果不传该字段，小明的年龄和小红的年龄会合并成一个单元格
 */
export function getRowSpanFromTable(items, key, text, index, id?) {
  // 如果数据列表或者当前行值为空则直接跳过
  if (isEmptyData(items) || isEmptyData(text)) {
    return 1;
  }
  if (
    index === 0 ||
    JSON.stringify(items[index - 1][key]) !== JSON.stringify(text) ||
    (index > 0 && id && JSON.stringify(items[index - 1][id]) !== JSON.stringify(items[index][id]))
  ) {
    // 第一行
    // 第index行和第index-1行值不相等
    // 从第二行开始，传额外主键id，主键value不相等
    let num = 1;
    if (id) {
      for (let i = 1, length = items.length - index + 1; i < length; i++) {
        if (
          items[index + i] &&
          JSON.stringify(items[index + i][key]) === JSON.stringify(text) &&
          JSON.stringify(items[index + i][id]) === JSON.stringify(items[index][id])
        ) {
          num++;
        } else {
          break;
        }
      }
    } else {
      for (let i = 1, length = items.length - index + 1; i < length; i++) {
        if (items[index + i] && JSON.stringify(items[index + i][key]) === JSON.stringify(text)) {
          num++;
        } else {
          break;
        }
      }
    }
    return num;
  } else {
    return 0;
  }
}

export function doJumpUrl(toUrl, thisPage?) {
  if (window.location.pathname === toUrl) {
    window.location.href = window.location.href + '';
  } else {
    if (thisPage || (isPc && isLark)) {
      window.location.href = toUrl;
    } else {
      window.open(toUrl, '_blank');
    }
  }
}
export function pad(toPad, size, padChar) {
  if (!padChar) {
    padChar = '0';
  }
  let s = toPad + '';
  while (s.length < size) s = padChar + s;
  return s;
}

export function getMonth(delta) {
  delta = delta || 0;
  return dayjs().add(delta, 'month').format('YYYY-MM');
}
export function urlContains(str) {
  return window.location.href.indexOf(str) !== -1;
}

export function mapItems(items) {
  return items.map((x) => ({ text: x.name, value: x.id }));
}

export function getTableFields(config: BasicTableProps) {
  const fields = config.columns
    .filter((x) => !!x.dataIndex)
    .filter((x) => !x.ignoreField)
    .map((x) => x.dataIndex);
  fields.push('id');
  config.columns.filter((h) => h.colorField).forEach((h) => fields.push(h.colorField));
  return fields;
}

export function closeWindow() {
  window.location.href = 'about:blank';
  window.close();
}

export function isEmptyData(data) {
  if (isNil(data) || data === '') {
    return true;
  } else if (typeof data === 'number' && isNaN(data)) {
    return true;
  } else if (typeof data === 'object') {
    return isEmpty(data);
  } else {
    return false;
  }
}
export function getTextFromValue(items, val, valueField = 'value', textField = 'text') {
  const findItem = items.find((item) => item[valueField] === val);
  if (!isEmptyData(findItem) && !isEmptyData(findItem[textField])) {
    return findItem[textField];
  } else {
    return '-';
  }
}
export function getValueById(obj: SelectOption[], value: string | number) {
  if (isEmptyData(value)) {
    return '';
  }
  return obj.find((item) => item.id === value) ? obj.find((item) => item.id === value)!.name : '';
}

export function getColSpanItem(colData) {
  const columns = colData.columns;
  const columnsData = colData.columnsData;
  const columnsNum = sum(columns.filter((c) => c.colSpan && c.colSpan > 0).map((c) => c.colSpan));
  columns.forEach((item, index) => {
    item.dataIndex = `index${index}`;
    item.customRenderItems = [];
  });
  const dataList: Recordable[] = [];
  columnsData.forEach((item, i) => {
    const data: any = {};
    let colSpan = 0;
    item.forEach((val, index) => {
      if (val.colSpan === 0) {
        columns[index].customRenderItems.push({ index: i, colSpan: 0 });
      } else if (val.colSpan > 0) {
        if (colSpan < columnsNum) {
          if (columnsNum - colSpan - val.colSpan >= 0) {
            colSpan += val.colSpan;
            columns[index].customRenderItems.push({ index: i, colSpan: val.colSpan });
          } else {
            columns[index].customRenderItems.push({ index: i, colSpan: columnsNum - colSpan });
            colSpan = columnsNum;
          }
        } else {
          columns[index].customRenderItems.push({ index: i, colSpan: 0 });
        }
      } else {
        if (colSpan <= columnsNum) {
          colSpan += 1;
          columns[index].customRenderItems.push({ index: i, colSpan: 1 });
        } else {
          columns[index].customRenderItems.push({ index: i, colSpan: 0 });
        }
      }
      data[`index${index}`] = val.value;
    });
    dataList.push(data);
  });
  columns.forEach((item) => {
    item.customRender = ({ text, index }) => {
      const obj = {
        children: text,
        props: {} as any,
      };
      item.customRenderItems.forEach((item) => {
        if (index === item.index) {
          obj.props.colSpan = item.colSpan;
          return;
        }
      });
      return obj;
    };
  });
  return { columns: columns, columnsData: dataList };
}

export function getIsEmptyItemList(dataList: Recordable[], items: string[]): string[] {
  const isEmptyItemList: string[] = [];
  for (let i = 0; i < dataList.length; i++) {
    if (isEmptyItemList.length === items.length) {
      break;
    } else {
      const dataItem = dataList[i];
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (isEmptyData(dataItem[item])) {
          if (!isEmptyItemList.includes(item)) {
            isEmptyItemList.push(item);
          }
        }
      }
    }
  }
  return isEmptyItemList;
}

export function getObjectDiff(obj1, obj2) {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  return diff;
}

// ↓-----sophon-----↓
/**
 * file对象转成字符串
 * @param file
 */
export async function fileToStr(file: any) {
  return new Promise((resolve, _reject) => {
    const reader = new FileReader();
    reader.readAsText(file, 'UTF-8');
    reader.onload = (e) => {
      if (e.target) {
        const fileString = e.target.result;
        resolve(fileString);
      }
    };
  });
}
export function getResourceType(ext: string) {
  if (/png|jpg|jpeg/.test(ext)) {
    return 'image';
  } else if (/mp3|wav/.test(ext)) {
    return 'audio';
  } else if (/mp4/.test(ext)) {
    return 'video';
  }
  return '';
}

/**
 * @description 通过值查找对象的 key
 * @param {object} target - 要查找的对象
 * @param {string} value - 要查找的值
 * @returns {string} key  返回的 key
 */
export function findKeyByValue(target: { [key: string]: string }, value: string): string {
  const keys = Reflect.ownKeys(target) as Array<string>;
  for (let i = 0; i < keys.length; i++) {
    if (target[keys[i]] === value) {
      return keys[i];
    }
  }
  return '';
}

export function strToFile(str: string, name: string) {
  return new Promise((resolve, _reject) => {
    const blob = new Blob([str], { type: 'text/plain;charset=utf-8' });
    const file = new File([blob], name, { type: 'text/plain;charset=utf-8' });
    resolve(file);
  });
}

/**
 * base64转file文件
 * @param base64
 * @param filename
 */
export function base64ToFile(base64: string, filename: string) {
  const arr = base64.split(',');
  const mimes = arr[0].match(/:(.*?);/);
  let mime;
  if (mimes) {
    mime = mimes[1];
  }
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

/**
 * 字节格式化
 * @param bytes
 */
export function bytesToSize(bytes: number) {
  if (bytes === 0) return '0 B';
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return (bytes / Math.pow(k, i)).toFixed(2) + ' ' + sizes[i];
}

/**
 * 保存文件到本地
 * @param content
 * @param name
 */
export function saveAs(content: string | Blob, name: string) {
  const downLink = document.createElement('a');
  downLink.download = name;
  let blob;
  if (content instanceof String) {
    blob = new Blob([content]);
  } else if (content instanceof Blob) {
    blob = content;
  }
  if (/base64/.test(content as string)) {
    downLink.href = content as string;
  } else {
    downLink.href = URL.createObjectURL(blob);
  }
  document.body.appendChild(downLink);
  downLink.click();
  document.body.removeChild(downLink);
}
export function isUTF8(bytes) {
  let i = 0;
  while (i < bytes.length) {
    if (
      // ASCII
      bytes[i] == 0x09 ||
      bytes[i] == 0x0a ||
      bytes[i] == 0x0d ||
      (0x20 <= bytes[i] && bytes[i] <= 0x7e)
    ) {
      i += 1;
      continue;
    }

    if (
      // non-overlong 2-byte
      0xc2 <= bytes[i] &&
      bytes[i] <= 0xdf &&
      0x80 <= bytes[i + 1] &&
      bytes[i + 1] <= 0xbf
    ) {
      i += 2;
      continue;
    }

    if (
      // excluding overlongs
      (bytes[i] == 0xe0 &&
        0xa0 <= bytes[i + 1] &&
        bytes[i + 1] <= 0xbf &&
        0x80 <= bytes[i + 2] &&
        bytes[i + 2] <= 0xbf) || // straight 3-byte
      (((0xe1 <= bytes[i] && bytes[i] <= 0xec) || bytes[i] == 0xee || bytes[i] == 0xef) &&
        0x80 <= bytes[i + 1] &&
        bytes[i + 1] <= 0xbf &&
        0x80 <= bytes[i + 2] &&
        bytes[i + 2] <= 0xbf) || // excluding surrogates
      (bytes[i] == 0xed && 0x80 <= bytes[i + 1] && bytes[i + 1] <= 0x9f && 0x80 <= bytes[i + 2] && bytes[i + 2] <= 0xbf)
    ) {
      i += 3;
      continue;
    }

    if (
      // planes 1-3
      (bytes[i] == 0xf0 &&
        0x90 <= bytes[i + 1] &&
        bytes[i + 1] <= 0xbf &&
        0x80 <= bytes[i + 2] &&
        bytes[i + 2] <= 0xbf &&
        0x80 <= bytes[i + 3] &&
        bytes[i + 3] <= 0xbf) || // planes 4-15
      (0xf1 <= bytes[i] &&
        bytes[i] <= 0xf3 &&
        0x80 <= bytes[i + 1] &&
        bytes[i + 1] <= 0xbf &&
        0x80 <= bytes[i + 2] &&
        bytes[i + 2] <= 0xbf &&
        0x80 <= bytes[i + 3] &&
        bytes[i + 3] <= 0xbf) || // plane 16
      (bytes[i] == 0xf4 &&
        0x80 <= bytes[i + 1] &&
        bytes[i + 1] <= 0x8f &&
        0x80 <= bytes[i + 2] &&
        bytes[i + 2] <= 0xbf &&
        0x80 <= bytes[i + 3] &&
        bytes[i + 3] <= 0xbf)
    ) {
      i += 4;
      continue;
    }
    return false;
  }
  return true;
}

/**
 * 生成时间戳
 */
export function createTimestamp() {
  const date = new Date();
  return (
    '' +
    date.getFullYear() +
    (date.getMonth() + 1) +
    date.getDate() +
    date.getHours() +
    date.getMinutes() +
    date.getSeconds()
  );
}
/**
 * file转base64
 * @param file
 */
export function fileToBase64(file: any): Promise<string> {
  return new Promise((resolve, _reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      if (e.target) {
        const base64 = e.target.result as string;
        resolve(base64);
      }
    };
  });
}
// ↑------sophon-------↑

export function formatNumberByNumeral(num, format) {
  return numeral(num).format(format);
}
