/**
 * Configure and register global directives
 */
import type { App } from 'vue';
import { setupLoadingDirective } from './loading';
import { setupPreventDoubleClickDirective } from './preventDoubleClick';
import { setupActiveDirective } from './active';

export function setupGlobDirectives(app: App) {
  setupLoadingDirective(app);
  setupPreventDoubleClickDirective(app);
  setupActiveDirective(app);
}
