import './public-path';
import { createApp } from 'vue';
import App from './App.vue';
import routes from './router';
import store from './store';
import '@/router/guard';
import { registerUI } from './expand/ui';
import { registerGlobalProperty } from './expand/global';
import animate from 'animate.css';
import './style/index.less';
import { setupGlobDirectives } from './directives';
import { DialogConfig } from './components/WeDialog/types';
import { DrawerConfig } from './components/WeDrawer/types';
import { BasicTableProps } from './components/WeTable';
import { cacheStore } from './store/modules/cache';
import { is_in_qiankun, qiankun_path_suffix } from './utils/const';
import { createRouter, createWebHistory } from 'vue-router';
import { createPermissionGuard } from '@/router/guard';
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $showDialog: (weDialog: DialogConfig) => void;
    $clipboard: any;
    $message: { error: Function; success: Function; info: Function; warning: Function };
    $confirm: (
      message: string,
      title: string,
      button: {
        confirmButtonText: string;
        cancelButtonText?: string;
        type: string;
        width?: number;
      }
    ) => Promise<void>;
    $showDrawer: (drawerConfig: DrawerConfig) => void;
    $showAlert: (
      title: string,
      alertContent: string | { config: BasicTableProps; [key: string]: any },
      callback?: Fn,
      cancel?: Fn,
      moreConfig?: Recordable
    ) => void;
    $setGlobalState: (params: Recordable) => void;
  }
}
let router: any = null;
let instance: any = null;
let history: any = null;

function storeData(props) {
  props.onGlobalStateChange &&
    props.onGlobalStateChange((value, _prev) => {
      localStorage.setItem('jwt_token', value.jwt_token);
      localStorage.setItem('app_env', value.app_env);
      cacheStore.SET_USER_INFO(value.user.opa_config);
      cacheStore.SET_APPINFO(value.appInfo);
    }, true);
}
/**
 * 应用每次进入都会调用 mount 方法，通常我们在这里触发应用的渲染方法
 */
function render(props: Recordable = {}) {
  const { container } = props;
  history = createWebHistory(is_in_qiankun ? qiankun_path_suffix : '/');
  router = createRouter({
    history,
    routes,
  });
  instance = createApp(App);
  const win: any = window; //
  if (process.env.NODE_ENV === 'development') {
    if ('__VUE_DEVTOOLS_GLOBAL_HOOK__' in win) {
      win.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = instance;
    }
  }
  if (!is_in_qiankun) {
    createPermissionGuard(router);
  }
  registerUI(instance);
  registerGlobalProperty(instance);
  setupGlobDirectives(instance);
  instance.use(router);
  instance.use(animate);
  instance.use(store);
  instance.mount(container ? container.querySelector('#app') : '#app');
}
if (!is_in_qiankun) {
  render();
}
export async function bootstrap() {
  console.log('react app bootstraped');
}

export async function mount(props: any) {
  render(props);
  storeData(props);
  instance.config.globalProperties.$onGlobalStateChange = props.onGlobalStateChange;
  instance.config.globalProperties.$setGlobalState = props.setGlobalState;
}
/**
 * 应用每次 切出/卸载 会调用的方法，通常在这里我们会卸载微应用的应用实例
 */
export async function unmount() {
  instance.unmount();
  instance._container.innerHTML = '';
  history.destroy();
  router = null;
  instance = null;
}
