
  import { defineComponent } from 'vue';
  export default defineComponent({
    name: 'SiderMenuItem',
    props: {
      item: {
        required: true,
        type: Object,
      },
    },
  });
