import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
const persistedMutation = ['SET_USER_INFO', 'TOGGLE_SIDEBAR_COLLAPSED'];
const persistedConfig = {
  key: 'opa_app',
  paths: ['cache.userInfo', 'cache.sidebarCollapsed'],
  filter: ({ type }) => {
    return persistedMutation.includes(type);
  },
};
export default createStore({
  plugins: [createPersistedState(persistedConfig)],
  devtools: true,
});
