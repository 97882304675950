import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "table-header" }
const _hoisted_2 = {
  key: 0,
  class: "title"
}
const _hoisted_3 = {
  key: 1,
  class: "title"
}
const _hoisted_4 = { class: "flex_column_center" }
const _hoisted_5 = { class: "button-group" }
const _hoisted_6 = { style: {"margin-left":"24px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_checkbox_group = _resolveComponent("a-checkbox-group")!
  const _component_a_popover = _resolveComponent("a-popover")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!Object.keys(_ctx.$slots).includes('table_title'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "table_title")
        ])),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", _hoisted_5, [
        _renderSlot(_ctx.$slots, "headerButton")
      ]),
      _createElementVNode("span", _hoisted_6, [
        _createVNode(_component_a_dropdown, null, {
          overlay: _withCtx(() => [
            _createVNode(_component_a_menu, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_menu_item, { onClick: _ctx.refreshData }, {
                  default: _withCtx(() => [
                    _createTextVNode("刷新表格")
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_a_menu_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_popover, {
                      placement: "bottomLeft",
                      trigger: "click"
                    }, {
                      content: _withCtx(() => [
                        _createVNode(_component_a_row, { style: {"width":"200px"} }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 24 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_checkbox, {
                                  checked: _ctx.checkAll,
                                  "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkAll) = $event)),
                                  onChange: _ctx.onCheckAllChange
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("全选")
                                  ]),
                                  _: 1
                                }, 8, ["checked", "onChange"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_col, { span: 24 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_checkbox_group, {
                                  value: _ctx.checkedList,
                                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.checkedList) = $event)),
                                  onChange: _ctx.onChange,
                                  ref: "columnListRef"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_row, null, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.plainOptions, (item) => {
                                          return (_openBlock(), _createBlock(_component_a_col, {
                                            span: 24,
                                            key: item.value
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_a_checkbox, {
                                                value: item.value
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(item.label), 1)
                                                ]),
                                                _: 2
                                              }, 1032, ["value"])
                                            ]),
                                            _: 2
                                          }, 1024))
                                        }), 128))
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["value", "onChange"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      default: _withCtx(() => [
                        _createTextVNode(" 列配置 ")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_menu_item, { onClick: _ctx.exportExcel }, {
                  default: _withCtx(() => [
                    _createTextVNode("导出数据")
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                (_ctx.show_sum)
                  ? (_openBlock(), _createBlock(_component_a_menu_item, {
                      key: 0,
                      onClick: _ctx.sumData
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("计算各类数值总和")
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.moreFunc, (item) => {
                  return (_openBlock(), _createBlock(_component_a_menu_item, {
                    onClick: item.click,
                    key: item.title
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.title), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_svg_icon, {
              "icon-name": "MenuOutlined",
              style: {"font-size":"20px"}
            })
          ]),
          _: 1
        })
      ])
    ])
  ]))
}