import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "svg_span_middle_center" }
const _hoisted_2 = { class: "svg_span_middle_center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_sider_menu_item = _resolveComponent("sider-menu-item", true)!
  const _component_a_sub_menu = _resolveComponent("a-sub-menu")!

  return (!_ctx.item.children)
    ? (_openBlock(), _createBlock(_component_a_menu_item, {
        key: _ctx.item.path,
        class: "font_14_color_72_bolder"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, [
            (_ctx.item.icon)
              ? (_openBlock(), _createBlock(_component_svg_icon, {
                  key: 0,
                  "icon-name": _ctx.item.icon
                }, null, 8, ["icon-name"]))
              : _createCommentVNode("", true),
            _createElementVNode("span", null, _toDisplayString(_ctx.item.title), 1)
          ])
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_a_sub_menu, {
        key: _ctx.item.path,
        class: "font_14_color_72_bolder"
      }, {
        title: _withCtx(() => [
          _createElementVNode("span", _hoisted_2, [
            (_ctx.item.icon)
              ? (_openBlock(), _createBlock(_component_svg_icon, {
                  key: 0,
                  "icon-name": _ctx.item.icon
                }, null, 8, ["icon-name"]))
              : _createCommentVNode("", true),
            _createElementVNode("span", null, _toDisplayString(_ctx.item.title), 1)
          ])
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.children, (child) => {
            return (_openBlock(), _createBlock(_component_sider_menu_item, {
              key: child.path + child.title,
              item: child
            }, null, 8, ["item"]))
          }), 128))
        ]),
        _: 1
      }))
}