import type { BasicTableProps, TableRowSelection } from '../types/table';
import { Table } from 'ant-design-vue';

import { computed, ref, unref, ComputedRef } from 'vue';
export function useRowSelection(propsRef: ComputedRef<BasicTableProps>, emit: EmitType, getDataSource: any) {
  const selectedRowKeysRef = ref<string[]>([]);
  const selectedRowRef = ref<Recordable[]>([]);

  const getRowSelectionRef = computed((): TableRowSelection | null => {
    const { rowSelection, selections } = unref(propsRef);
    if (!rowSelection) {
      return null;
    }
    let selection_items: any = null;
    if (selections!.length > 0) {
      selection_items = selections!.map((item) => ({
        key: item.key,
        text: item.text,
        onSelect: (changableRowKeys) => {
          selectedRowKeysRef.value = item.onSelect(changableRowKeys);
          const data_source = getDataSource();
          selectedRowRef.value = selectedRowKeysRef.value.map((key) => data_source.find((a_item) => a_item.id === key));
        },
      }));
      selection_items.unshift(Table.SELECTION_NONE);
    }
    return {
      selectedRowKeys: unref(selectedRowKeysRef),
      onChange: (selectedRowKeys: string[], selectedRows: Recordable[]) => {
        selectedRowKeysRef.value = selectedRowKeys;
        selectedRowRef.value = selectedRows;
        emit('selection-change', {
          keys: selectedRowKeys,
          rows: selectedRows,
        });
      },
      selections: selection_items,
      ...(rowSelection === undefined ? {} : rowSelection),
    };
  });
  // 设置已选择数据
  function setSelectedRows(rows: Recordable[]) {
    selectedRowRef.value = rows;
  }
  // 清除已选择的数据
  function clearSelectedRowKeys() {
    selectedRowRef.value = [];
    selectedRowKeysRef.value = [];
  }
  // 获取行key
  function getSelectRowKeys() {
    return unref(selectedRowKeysRef);
  }
  // 获取选择的数据
  function getSelectRows<T = Recordable>() {
    // const ret = toRaw(unref(selectedRowRef)).map((item) => toRaw(item));
    return unref(selectedRowRef) as T[];
  }

  return {
    getRowSelectionRef,
    getSelectRows,
    getSelectRowKeys,
    clearSelectedRowKeys,
    setSelectedRows,
  };
}
