/* eslint-disable camelcase */
import request from '@/utils/request';
import { SelectOption } from 'types/store';

// 获取主题区域列表
export function getReginList() {
  return request.get<null, SelectOption[]>('/api/config/region/list');
}
// 获取时区
export function getTimeZoneList() {
  return request.get<null, string[]>('/api/config/timezone/list');
}
