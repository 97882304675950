
  import { defineComponent, computed, nextTick } from 'vue';
  import SiderMenu from './components/SiderMenu.vue';
  import { appStore } from '@/store/modules/app';
  import { cacheStore } from '@/store/modules/cache';
  import { useUser } from '@/hooks/useUser';

  export default defineComponent({
    name: 'AppLayoutSider',
    components: {
      SiderMenu,
    },
    setup() {
      const collapsed = computed({
        get() {
          return cacheStore.sidebarCollapsed;
        },
        set(val: boolean) {
          if (val === false) {
            appStore.SET_DRAWER_CONFIG(null);
          }
          nextTick(() => {
            cacheStore.TOGGLE_SIDEBAR_COLLAPSED(val);
          });
        },
      });
      const { MenusByRoles } = useUser();
      return {
        collapsed,
        MenusByRoles,
      };
    },
    watch: {
      collapsed: {
        handler() {
          setTimeout(() => {
            // 必须要延迟，document的刷新computed不到。
            appStore.SET_SLIDE_WIDTH(document.getElementById('app_sider')?.clientWidth || 0);
          }, 300);
        },
        immediate: true,
      },
    },
    created() {},
    methods: {},
  });
