import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sider_menu_item = _resolveComponent("sider-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!

  return (_openBlock(), _createBlock(_component_a_menu, {
    mode: "inline",
    class: "sider_menu",
    "inline-collapsed": _ctx.collapsed,
    openKeys: _ctx.openKeys,
    "onUpdate:openKeys": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.openKeys) = $event)),
    selectedKeys: _ctx.selectedKeys,
    "onUpdate:selectedKeys": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedKeys) = $event)),
    onClick: _ctx.handleMenuClick
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, (menu) => {
        return (_openBlock(), _createBlock(_component_sider_menu_item, {
          key: menu.path + menu.title,
          item: menu
        }, null, 8, ["item"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["inline-collapsed", "openKeys", "selectedKeys", "onClick"]))
}