import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, createBlock as _createBlock, normalizeClass as _normalizeClass, createSlots as _createSlots, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "dropdown_icon" }
const _hoisted_3 = {
  key: 1,
  class: "dropdown_icon"
}
const _hoisted_4 = {
  key: 2,
  class: "dropdown_icon"
}
const _hoisted_5 = { class: "page_wrapper_icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_back_top = _resolveComponent("a-back-top")!
  const _component_a_affix = _resolveComponent("a-affix")!

  return (_openBlock(), _createElementBlock("div", {
    id: "page_detail_wrapper",
    ref: "pageDetailWrapperEL",
    class: _normalizeClass([{ page_detail_wrapper: true }, _ctx.PageDetailWrapperClass]),
    style: _normalizeStyle({ height: _ctx.refHeight })
  }, [
    (_ctx.showHeader)
      ? (_openBlock(), _createBlock(_component_a_row, {
          key: 0,
          class: "header",
          type: "flex",
          justify: "space-between"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { class: "title" }, {
              default: _withCtx(() => [
                (_ctx.computedTitle !== '')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.computedTitle), 1))
                  : (_ctx.titleType === 'slot')
                    ? _renderSlot(_ctx.$slots, "title", { key: 1 })
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                        _createTextVNode("面包屑")
                      ], 64))
              ]),
              _: 3
            }),
            _createVNode(_component_a_col, { class: "dropdown" }, {
              default: _withCtx(() => [
                (_ctx.dropdonwMenus.length !== 0)
                  ? (_openBlock(), _createBlock(_component_a_dropdown, { key: 0 }, {
                      overlay: _withCtx(() => [
                        _createVNode(_component_a_menu, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dropdonwMenus, (item) => {
                              return (_openBlock(), _createBlock(_component_a_menu_item, {
                                key: item.label,
                                onClick: item.onClick
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.label), 1)
                                ]),
                                _: 2
                              }, 1032, ["onClick"]))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                          _createVNode(_component_svg_icon, { "icon-name": "MenuOutlined" })
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.showClose && _ctx.redirectHref !== '')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_component_svg_icon, {
                        "icon-name": "FullscreenOutlined",
                        onClick: _ctx.EnlargePageDetail
                      }, null, 8, ["onClick"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.showClose)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_svg_icon, {
                        "icon-name": "CloseOutlined",
                        onClick: _ctx.closeDrawer
                      }, null, 8, ["onClick"])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 3
        }))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.buttons.length !== 0)
      ? (_openBlock(), _createBlock(_component_a_row, {
          key: 1,
          class: "footer",
          style: _normalizeStyle({ width: _ctx.refWidth })
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (btn) => {
                  return (_openBlock(), _createBlock(_component_a_button, {
                    key: btn.label,
                    onClick: btn.onClick,
                    type: btn.type,
                    class: _normalizeClass(btn.class),
                    ghost: btn.ghost,
                    danger: btn.danger || false,
                    style: {"margin-right":"16px"}
                  }, _createSlots({
                    default: _withCtx(() => [
                      _createTextVNode(" " + _toDisplayString(btn.label), 1)
                    ]),
                    _: 2
                  }, [
                    (btn.icon)
                      ? {
                          name: "icon",
                          fn: _withCtx(() => [
                            _createVNode(_component_svg_icon, {
                              "icon-name": btn.icon
                            }, null, 8, ["icon-name"])
                          ]),
                          key: "0"
                        }
                      : undefined
                  ]), 1032, ["onClick", "type", "class", "ghost", "danger"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["style"]))
      : _createCommentVNode("", true),
    _createVNode(_component_a_back_top, {
      target: _ctx.getTarget(),
      visibilityHeight: 100,
      ref: "pagebackup"
    }, null, 8, ["target"]),
    (_ctx.affixisshow)
      ? (_openBlock(), _createBlock(_component_a_affix, {
          key: 2,
          class: "page_wrapper_affix"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_dropdown, { placement: "topCenter" }, {
              overlay: _withCtx(() => [
                _createVNode(_component_a_menu, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.anchors, (anchor) => {
                      return (_openBlock(), _createBlock(_component_a_menu_item, {
                        key: anchor.id,
                        onClick: ($event: any) => (_ctx.scrollIntoView(anchor.id))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(anchor.label), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_svg_icon, { "icon-name": "UnorderedListOutlined" })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 6))
}