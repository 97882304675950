import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createSlots as _createSlots, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "dropdown_icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ page_wrapper: true }, _ctx.pageWrapperClass]),
    id: "page_wrapper",
    ref: "pageWrapperEL"
  }, [
    (_ctx.showTitle)
      ? (_openBlock(), _createBlock(_component_a_row, {
          key: 0,
          class: "header",
          type: "flex",
          justify: "space-between"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { class: "title" }, {
              default: _withCtx(() => [
                (_ctx.computedTitle !== '')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.computedTitle), 1))
                  : (_ctx.titleType === 'slot')
                    ? _renderSlot(_ctx.$slots, "title", { key: 1 })
                    : (_openBlock(), _createBlock(_component_a_breadcrumb, { key: 2 }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadItems, (item, index) => {
                            return (_openBlock(), _createBlock(_component_a_breadcrumb_item, { key: index }, _createSlots({
                              default: _withCtx(() => [
                                (!item.children && index !== _ctx.breadItems.length - 1)
                                  ? (_openBlock(), _createElementBlock("a", {
                                      key: 0,
                                      target: "_self",
                                      rel: "noopener noreferrer",
                                      href: item.path
                                    }, _toDisplayString(item.title), 9, _hoisted_2))
                                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                      _createTextVNode(_toDisplayString(item.title), 1)
                                    ], 64))
                              ]),
                              _: 2
                            }, [
                              (item.children && index !== _ctx.breadItems.length - 1)
                                ? {
                                    name: "overlay",
                                    fn: _withCtx(() => [
                                      _createVNode(_component_a_menu, null, {
                                        default: _withCtx(() => [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (child) => {
                                            return (_openBlock(), _createBlock(_component_a_menu_item, {
                                              key: child.path
                                            }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("a", {
                                                  target: "_self",
                                                  rel: "noopener noreferrer",
                                                  href: child.path
                                                }, _toDisplayString(child.title), 9, _hoisted_3)
                                              ]),
                                              _: 2
                                            }, 1024))
                                          }), 128))
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    key: "0"
                                  }
                                : undefined
                            ]), 1024))
                          }), 128))
                        ]),
                        _: 1
                      }))
              ]),
              _: 3
            }),
            _createVNode(_component_a_col, { class: "dropdown" }, {
              default: _withCtx(() => [
                (_ctx.dropdonwMenus.length !== 0)
                  ? (_openBlock(), _createBlock(_component_a_dropdown, { key: 0 }, {
                      overlay: _withCtx(() => [
                        _createVNode(_component_a_menu, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dropdonwMenus, (item) => {
                              return (_openBlock(), _createBlock(_component_a_menu_item, {
                                key: item.label,
                                onClick: item.onClick
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.label), 1)
                                ]),
                                _: 2
                              }, 1032, ["onClick"]))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_svg_icon, { "icon-name": "MenuOutlined" })
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 3
        }))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}