import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  ref: "wrapRef",
  class: "table_container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableHeader = _resolveComponent("TableHeader")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_table_search_form = _resolveComponent("table-search-form")!
  const _component_table_info = _resolveComponent("table-info")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.getBindValues.showTableHeader)
      ? (_openBlock(), _createBlock(_component_TableHeader, {
          key: 0,
          moreFunc: _ctx.getBindValues.moreFunc,
          title: _ctx.getBindValues.table_title,
          onRefresh: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('refreshData'))),
          table_display_type: _ctx.table_display_type,
          onChange_display_type: _ctx.change_display_type
        }, _createSlots({
          headerButton: _withCtx(() => [
            _renderSlot(_ctx.$slots, "headerButton")
          ]),
          _: 2
        }, [
          (Object.keys(_ctx.$slots).includes('table_title'))
            ? {
                name: "table_title",
                fn: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "table_title")
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["moreFunc", "title", "table_display_type", "onChange_display_type"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.tabConfig && _ctx.tabConfig.tabItems && _ctx.tabConfig.tabItems.length !== 0)
        ? (_openBlock(), _createBlock(_component_a_tabs, {
            key: 0,
            class: "table_tab_container",
            activeKey: _ctx.computedActiveKey,
            "onUpdate:activeKey": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedActiveKey) = $event)),
            type: "card"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabConfig.tabItems, (item) => {
                return (_openBlock(), _createBlock(_component_a_tab_pane, {
                  key: item.key,
                  tab: item.tab
                }, null, 8, ["tab"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["activeKey"]))
        : _createCommentVNode("", true),
      (_ctx.getBindValues.useSearchForm)
        ? (_openBlock(), _createBlock(_component_table_search_form, _mergeProps({ key: 1 }, _ctx.getFormProps, {
            moreFunc: _ctx.getBindValues.moreFunc,
            reload: _ctx.tableAction.reload,
            onRegister: _ctx.registerForm,
            onTable_search_form_change: _ctx.handle_table_search_form_change,
            getOptions: _ctx.tableAction.getOptions,
            useWatchInitDefault: _ctx.getBindValues.useWatchInitDefault,
            need_view_filter: _ctx.getBindValues.need_view_filter,
            table_key: _ctx.getBindValues.table_key,
            ref: "table_search_form_ref"
          }), null, 16, ["moreFunc", "reload", "onRegister", "onTable_search_form_change", "getOptions", "useWatchInitDefault", "need_view_filter", "table_key"]))
        : _createCommentVNode("", true),
      (_ctx.getBindValues.showTableInfo)
        ? (_openBlock(), _createBlock(_component_table_info, { key: 2 }, {
            tableInfo: _withCtx(() => [
              _renderSlot(_ctx.$slots, "tableInfo")
            ]),
            _: 3
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_a_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, {
            span: _ctx.getBindValues.tableCol
          }, {
            default: _withCtx(() => [
              (_ctx.is_table_display)
                ? _withDirectives((_openBlock(), _createBlock(_component_a_table, _mergeProps({
                    key: 0,
                    ref: "tableElRef"
                  }, _ctx.getBindValues, { onChange: _ctx.handleTableChange }), _createSlots({
                    name: _withCtx(({ record }) => [
                      _createVNode(_component_a_button, {
                        type: "link",
                        onClick: ($event: any) => (_ctx.clickEntity(record))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(record.entity_name || record.show_name || record.name || ''), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, [
                    _renderList(Object.keys(_ctx.$slots), (item) => {
                      return {
                        name: item,
                        fn: _withCtx((data) => [
                          _createElementVNode("div", {
                            class: _normalizeClass({ 'slot-bold': data.__show_bold })
                          }, [
                            _renderSlot(_ctx.$slots, item, _normalizeProps(_guardReactiveProps(data)))
                          ], 2)
                        ])
                      }
                    }),
                    _renderList(_ctx.getColorFieldItem, (column) => {
                      return {
                        name: column.dataIndex,
                        fn: _withCtx(({ text, record }) => [
                          _createElementVNode("span", {
                            style: _normalizeStyle({ color: record[column.colorField] })
                          }, _toDisplayString(text), 5)
                        ])
                      }
                    })
                  ]), 1040, ["onChange"])), [
                    [_vShow, _ctx.getEmptyDataIsShowTable]
                  ])
                : _createCommentVNode("", true)
            ]),
            _: 3
          }, 8, ["span"]),
          (_ctx.getBindValues.tableCol !== 24)
            ? _renderSlot(_ctx.$slots, "right_content", { key: 0 })
            : _createCommentVNode("", true)
        ]),
        _: 3
      })
    ], 512)
  ], 64))
}