import type { BasicColumn, BasicTableProps, CellFormat, GetColumnsParams } from '../types/table';
import type { PaginationProps } from '../types/pagination';
import type { ComputedRef } from 'vue';

import { unref, Ref, computed, ref, toRaw, watch } from 'vue';
import isBoolean from 'lodash/isBoolean';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import isFunction from 'lodash/isFunction';
import cloneDeep from 'lodash/cloneDeep';
import dayjs from 'dayjs';
import { PAGE_SIZE } from '../const';
import { switchNumber, formatNumber } from '@/utils/number';
import { isEmptyData } from '@/utils/common';
const DEFAULT_ALIGN = 'left';
const DEFAULT_ELLIPISI = true;
const INDEX_COLUMN_FLAG = 'INDEX';
const ACTION_COLUMN_FLAG = 'ACTION';

function handleItem(item: BasicColumn, ellipsis: boolean, scroll: Recordable) {
  const { key, dataIndex } = item;
  item.thousands = !isEmptyData(item.thousands) && !item.thousands ? item.thousands : true;
  item.align = item.align || DEFAULT_ALIGN;
  if (item.componentType === 'we-number-range') {
    item.align = 'right';
  }
  item.ellipsis = isBoolean(ellipsis) ? item.ellipsis : DEFAULT_ELLIPISI;
  if (ellipsis) {
    if (!key) {
      item.key = dataIndex;
    }
    if (!isBoolean(item.ellipsis)) {
      Object.assign(item, {
        ellipsis,
      });
    }
  }
  if (item.flag === ACTION_COLUMN_FLAG) {
    item.fixed = 'right';
  }
  if (item.flag !== ACTION_COLUMN_FLAG && INDEX_COLUMN_FLAG !== item.flag && !item.componentType) {
    item.componentType = 'a-input';
  }
  if (isEmptyData(item.sorter) && item.flag !== ACTION_COLUMN_FLAG && INDEX_COLUMN_FLAG !== item.flag) {
    item.sorter = true;
  }
  if (isEmptyData(scroll) && item.width) {
    if (typeof item.width === 'string') {
      item.customCell = () => {
        return {
          style: { 'max-width': `${item.width}` },
        };
      };
    } else {
      item.customCell = () => {
        return {
          style: { 'max-width': `${item.width}px` },
        };
      };
    }
  }
}
// 用于定义化序号列
function handleIndexColumn(
  propsRef: ComputedRef<BasicTableProps>,
  getPaginationRef: ComputedRef<boolean | PaginationProps>,
  columns: BasicColumn[]
) {
  const { showIndexColumn, indexColumnProps } = unref(propsRef);
  let pushIndexColumns = false;
  columns.forEach(() => {
    const indIndex = columns.findIndex((column) => column.flag === INDEX_COLUMN_FLAG);
    if (showIndexColumn) {
      pushIndexColumns = indIndex === -1;
    } else if (!showIndexColumn && indIndex !== -1) {
      columns.splice(indIndex, 1);
    }
  });
  if (!pushIndexColumns) return;
  const isFixedLeft = columns.some((item) => item.fixed === 'left');

  columns.unshift({
    flag: INDEX_COLUMN_FLAG,
    title: '序号',
    width: 50,
    align: 'center',
    customRender: ({ index }) => {
      const getPagination = unref(getPaginationRef);
      if (isBoolean(getPagination)) {
        return `${index + 1}`;
      }
      const { current = 1, pageSize = PAGE_SIZE } = getPagination as PaginationProps;
      return ((current < 1 ? 1 : current) - 1) * pageSize + index + 1;
    },
    ...(isFixedLeft
      ? {
          fixed: 'left',
        }
      : {}),
    ...indexColumnProps,
  });
}
function handleActionColumn(propsRef: ComputedRef<BasicTableProps>, columns: BasicColumn[]) {
  const { actionColumn } = unref(propsRef);
  if (!actionColumn) return;
  const hasIndex = columns.findIndex((column) => column.flag === ACTION_COLUMN_FLAG);

  if (hasIndex === -1) {
    columns.push({
      ...columns[hasIndex],
      fixed: 'right',
      ...actionColumn,
      flag: ACTION_COLUMN_FLAG,
    });
  }
}
export function useColumns(
  propsRef: ComputedRef<BasicTableProps>,
  getPaginationRef: ComputedRef<boolean | PaginationProps>
) {
  const columnsRef = ref(unref(propsRef).columns) as unknown as Ref<BasicColumn[]>;
  const getColumnsRef = computed(() => {
    const columns = unref(columnsRef);
    handleIndexColumn(propsRef, getPaginationRef, columns);
    handleActionColumn(propsRef, columns);
    if (!columns) {
      return [];
    }
    const { ellipsis, scroll } = unref(propsRef);

    const cloneColumns = cloneDeep(columns);
    cloneColumns.forEach((item) => {
      // const { customRender, slots } = item;
      handleItem(item, Reflect.has(item, 'ellipsis') ? !!item.ellipsis : !!ellipsis, scroll as Recordable);
    });
    return cloneColumns;
  });
  watch(
    () => unref(propsRef).columns,
    (columns) => {
      columnsRef.value = columns;
    }
  );
  // 用于设置列配置
  function setColumns(columnList: BasicColumn[]) {
    const columns = cloneDeep(columnList);
    if (!isArray(columns)) return;

    if (columns.length <= 0) {
      columnsRef.value = [];
      return;
    }
    columnsRef.value = columns;
  }
  // 获取未被隐藏的columns
  const getViewColumns = (opt?: GetColumnsParams) => {
    const viewColumns = sortFixedColumn(unref(getColumnsRef));
    let columns = cloneDeep(viewColumns);
    // 格式化columns
    columns.forEach((column) => {
      const { slots, dataIndex, customRender, format, flag, round, numberFormat, thousands, componentType } = column;
      if (!slots || !slots?.title) {
        column.slots = { title: `header-${dataIndex}`, ...(slots || {}) };
        column.customTitle = column.title;
      }
      const isDefaultAction = [INDEX_COLUMN_FLAG, ACTION_COLUMN_FLAG].includes(flag!);
      // 自定义格式化单元格
      if (!customRender && format && !isDefaultAction) {
        column.customRender = ({ text, record, index }) => {
          return formatCell(text, format, record, index);
        };
      } else if (!slots && !customRender) {
        column.customRender = ({ text }) => {
          if (isEmptyData(text)) {
            return '-';
          }
          if (!isEmptyData(text) && isBoolean(text)) {
            return `${text}`;
          }
          if (round) {
            text = switchNumber(text, round);
          } else if (numberFormat) {
            text = formatNumber(text, numberFormat);
          }
          if (
            typeof Number(text) === 'number' &&
            !isEmptyData(Number(text)) &&
            thousands &&
            componentType === 'we-number-range'
          ) {
            text = Number(text).toLocaleString();
          }
          return text;
        };
      }
    });
    const { ignoreIndex, ignoreAction, sort } = opt || {};
    if (ignoreIndex) {
      columns = columns.filter((item) => item.flag !== INDEX_COLUMN_FLAG);
    }
    if (ignoreAction) {
      columns = columns.filter((item) => item.flag !== ACTION_COLUMN_FLAG);
    }
    if (sort) {
      columns = sortFixedColumn(columns);
    }
    return columns;
  };
  // 获取列配置
  function getColumns(opt?: GetColumnsParams) {
    const { ignoreIndex, ignoreAction, sort } = opt || {};
    let columns = toRaw(unref(getColumnsRef));
    if (ignoreIndex) {
      columns = columns.filter((item) => item.flag !== INDEX_COLUMN_FLAG);
    }
    if (ignoreAction) {
      columns = columns.filter((item) => item.flag !== ACTION_COLUMN_FLAG);
    }
    if (sort) {
      columns = sortFixedColumn(columns);
    }
    return columns;
  }
  return {
    getColumnsRef,
    getColumns,
    setColumns,
    getViewColumns,
  };
}
// 获取固定列配置
function sortFixedColumn(columns: BasicColumn[]) {
  const fixedLeftColumns: BasicColumn[] = [];
  const fixedRightColumns: BasicColumn[] = [];
  const defColumns: BasicColumn[] = [];
  for (const column of columns) {
    if (column.fixed === 'left') {
      fixedLeftColumns.push(column);
      continue;
    }
    if (column.fixed === 'right') {
      fixedRightColumns.push(column);
      continue;
    }
    defColumns.push(column);
  }
  const resultColumns = [...fixedLeftColumns, ...defColumns, ...fixedRightColumns].filter(
    (item) => !item.defaultHidden
  );

  return resultColumns;
}
// 格式化单元格
export function formatCell(text: string | number, format: CellFormat, record: Recordable, index: number) {
  if (!format) {
    return text;
  }
  if (isFunction(format)) {
    return format(text, record, index);
  }
  if (isEmptyData(text)) {
    return '-';
  }
  try {
    const DATE_FORMAT_PREFIX = 'date|';
    if (isString(format) && format.startsWith(DATE_FORMAT_PREFIX)) {
      const dateFormat = format.replace(DATE_FORMAT_PREFIX, '');

      if (!dateFormat) {
        return text;
      }
      return dayjs(text).format(dateFormat);
    }

    // enum
    if (isObject(format) && Reflect.has(format, 'size')) {
      return (format as any).get(text);
    }
  } catch (error) {
    return text;
  }
}
