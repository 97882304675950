import PageDetailWrapper from '@/components/PageDetailWrapper/index.vue';
import PageWrapper from '@/components/PageWrapper/index.vue';
import SvgIcon from '@/components/SvgIcon/index.vue'; // svg component
import WeDialog from '@/components/WeDialog/index.vue';
import WeDrawer from '@/components/WeDrawer/index.vue';
import WeSelect from '@/components/WeSelect/index.vue';
import { WeTable } from '@/components/WeTable';
import TableAction from '@/components/WeTable/components/TableAction.vue';
import {
  Affix,
  Anchor,
  AnchorLink,
  AutoComplete,
  Avatar,
  BackTop,
  Breadcrumb,
  Button,
  Card,
  Cascader,
  Checkbox,
  Col,
  Collapse,
  ConfigProvider,
  DatePicker,
  Divider,
  Drawer,
  Dropdown,
  Form,
  Input,
  InputNumber,
  InputPassword,
  Layout,
  List,
  Menu,
  Modal,
  Popover,
  Progress,
  Radio,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
  Tag,
  Timeline,
  TimePicker,
  Tooltip,
  Transfer,
  Upload,
  Steps,
  Step,
} from 'ant-design-vue';
import { App } from 'vue';

const compList = [
  Affix,
  Anchor,
  AnchorLink,
  AutoComplete,
  Avatar,
  BackTop,
  Breadcrumb,
  Breadcrumb.Item,
  Button,
  Card,
  Cascader,
  Checkbox,
  Checkbox.Group,
  Col,
  Collapse,
  Collapse.Panel,
  ConfigProvider,
  Divider,
  Drawer,
  Dropdown,
  Form,
  Form.Item,
  Layout,
  Layout.Content,
  Layout.Header,
  Layout.Sider,
  List,
  List.Item,
  Menu,
  Menu.SubMenu,
  Menu.Item,
  Menu.ItemGroup,
  Modal,
  Input,
  InputNumber,
  InputPassword,
  Input.Group,
  Input.TextArea,
  Radio,
  Radio.Group,
  Row,
  Select,
  Select.Option,
  Table,
  Table.Column,
  Tag,
  Tabs,
  Tabs.TabPane,
  TimePicker,
  Timeline,
  Timeline.Item,
  Tooltip,
  Upload,
  Popover,
  SvgIcon,
  Spin,
  TableAction,
  DatePicker,
  DatePicker.MonthPicker,
  DatePicker.RangePicker,
  Transfer,
  WeTable,
  WeSelect,
  WeDialog,
  PageWrapper,
  Progress,
  PageDetailWrapper,
  WeDrawer,
  Steps,
  Step,
];
export function registerUI(app: App) {
  compList.forEach((comp: any) => {
    app.component(comp.name || comp.displayName, comp);
  });
  app.component('a-select-option', Select.Option);
}
