
  import { defineComponent, ref } from 'vue';
  import XLSX from 'xlsx';
  import pick from 'lodash-es/pick';
  import { sheet2blob, openDownloadDialog } from '@/utils/excel';
  import { appStore } from '@/store/modules/app';
  import { isEmptyData } from '@/utils/common';
  export default defineComponent({
    name: 'WeImportExcel',
    props: ['value', 'config'],
    data: () => ({
      showPreviewDialog: false,
      title: 'Import Excel Data Preview',
    }),
    computed: {
      tableConfig() {
        const config = {
          useSearchForm: false,
          useFilterFromColumns: false,
          showTableHeader: false,
          tabConfig: null,
          columns: [],
          pagination: false,
        };
        if (this.config && this.config.headers) {
          if (this.config.headers[0].key) {
            config.columns = this.config.headers.map((x) => ({ title: x.label, dataIndex: x.key }));
          } else {
            config.columns = this.config.headers.map((x) => ({ title: x, dataIndex: x }));
          }
        }
        return config;
      },
    },
    setup() {
      const excelName = ref<Nullable<string>>(null);
      const excelData = ref<Recordable[]>([]);
      return {
        excelName,
        excelData,
      };
    },
    methods: {
      isEmptyData,
      selectFile({ file }) {
        const fileExtension = file.name.replace(/^.*\./, '');
        const vm = this;
        const reader = new FileReader();
        function handleJson(json) {
          json.forEach((x) => {
            Object.keys(x).forEach((key) => {
              if (key.trim() !== key) {
                x[key.trim()] = x[key];
              }
            });
          });
          let pickedHeaderResult: Recordable[] = [];
          if (vm.config.headers[0].key) {
            pickedHeaderResult = vm.config.headers
              ? json.map((x) => {
                  const temp: Recordable = {};
                  vm.config.headers.forEach((item) => {
                    temp[item.key] = x[item.label];
                  });
                  return temp;
                })
              : json;
          } else {
            pickedHeaderResult = vm.config.headers ? json.map((x) => pick(x, vm.config.headers)) : json;
          }
          let convertedResult = vm.config.converter ? vm.config.converter(pickedHeaderResult) : pickedHeaderResult;
          if (vm.config.timeHeaders) {
            pickedHeaderResult.forEach((x) => {
              vm.config.timeHeaders.forEach((f) => {
                if (typeof x[f] === 'number') {
                  x[f] = XLSX.SSF.format('yyyy-mm-dd hh:MM:ss', x[f]);
                }
              });
            });
          }
          if (isEmptyData(vm.excelData)) {
            vm.excelData = convertedResult;
          } else {
            vm.excelData = vm.excelData.concat(convertedResult);
          }
          vm.$emit('update:value', vm.excelData);
        }
        if (fileExtension === 'csv') {
          let config = {
            delimiter: '', // auto-detect
            newline: '', // auto-detect
            quoteChar: '"',
            escapeChar: '"',
            header: false,
            trimHeader: false,
            dynamicTyping: false,
            preview: 0,
            encoding: '',
            worker: false,
            comments: false,
            step: undefined,
            complete: undefined,
            error: undefined,
            download: false,
            skipEmptyLines: false,
            chunk: undefined,
            fastMode: undefined,
            beforeFirstChunk: undefined,
            withCredentials: undefined,
          };
          reader.readAsText(file, 'GBK');
          reader.onload = function (e) {
            const data = e.target?.result;
            const json: any = window.Papa.parse(data, config);
            const header = json.data.splice(0, 1)[0];
            const list: Recordable[] = [];
            json.data.forEach((item) => {
              const obj = {};
              header.forEach((key, index) => {
                obj[key] = item[index];
              });
              list.push(obj);
            });
            handleJson(list);
          };
        } else {
          const sheet_to_json_config = {
            raw: false,
            dateNF: 'yyyy-mm-dd',
            ...(isEmptyData(vm.config.json_config) ? {} : vm.config.json_config),
          };
          reader.readAsArrayBuffer(file);
          reader.onload = function (e) {
            const data = e.target?.result;
            const v8 = new Uint8Array(data as ArrayBuffer);
            const wb = XLSX.read(v8, { type: 'array', cellDates: true, cellText: false });
            const json: any = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], sheet_to_json_config);
            handleJson(json);
          };
        }
        if (isEmptyData(vm.excelName)) {
          vm.excelName = file.name;
        } else {
          vm.excelName =
            vm.excelName + `<div style="border-bottom: solid thin #ccc; margin: 4px 0;"></div>` + file.name;
        }
      },
      tipHeaders() {
        if (this.config && this.config.headers) {
          let headersTip = '';
          if (this.config.headers[0].key) {
            headersTip = this.config.headers.map((x) => `<li>${x.label}</li>`).join('');
          } else {
            headersTip = this.config.headers.map((x) => `<li>${x}</li>`).join('');
          }
          let exampleData: Recordable[] = [];
          if (this.config.example) {
            exampleData = this.config.example;
          } else {
            const defaultExample = {};
            if (this.config.headers[0].key) {
              this.config.headers.forEach((x) => (defaultExample[x.label] = ''));
            } else {
              this.config.headers.forEach((x) => (defaultExample[x] = ''));
            }
            exampleData.push(defaultExample);
          }
          this.$showAlert(
            'Required Excel Headers',
            `<div style="margin-left: 40px;">${headersTip}</div>`,
            () => {
              const sheet = XLSX.utils.json_to_sheet(exampleData, { raw: false } as any);
              openDownloadDialog(sheet2blob(sheet), `Template-Example.xlsx`);
              appStore.SET_ALERT_CONFIG(null);
            },
            undefined,
            { okText: 'Download Template' }
          );
        }
      },
    },
  });
