import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "table_form_container" }
const _hoisted_2 = { class: "form_container" }
const _hoisted_3 = { class: "buttons_container" }
const _hoisted_4 = { class: "save_view_wrapper" }
const _hoisted_5 = { class: "view_wrapper" }
const _hoisted_6 = {
  class: "view_item",
  style: { 'background-color': '#f6f7fb' }
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_form, {
        ref: "TableSearchFormElRef",
        model: _ctx.formState
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_row, { gutter: 8 }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form_list, (item, index) => {
                return _withDirectives((_openBlock(), _createBlock(_component_a_col, {
                  class: "form_item_col",
                  span: item.componentType === 'a-range-picker' || item.componentType === 'we-number-range' ? 12 : 6,
                  key: item.field
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_tooltip, null, _createSlots({
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, null, {
                          default: _withCtx(() => [
                            (_openBlock(), _createBlock(_resolveDynamicComponent(item.componentType), _normalizeProps(_guardReactiveProps({ ..._ctx.getComponentAttr(item) })), null, 16))
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, [
                      (!_ctx.isEmptyData(_ctx.formState[item.field]))
                        ? {
                            name: "title",
                            fn: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.label) + "：" + _toDisplayString(_ctx.formState[item.field]), 1)
                            ]),
                            key: "0"
                          }
                        : undefined
                    ]), 1024)
                  ]),
                  _: 2
                }, 1032, ["span"])), [
                  [_vShow, index < _ctx.count && item.showElement]
                ])
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_a_dropdown, null, {
        overlay: _withCtx(() => [
          _createVNode(_component_a_menu, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_a_button, {
                  type: "link",
                  onClick: _ctx.save_current_view
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("保存当前视图")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_a_button, {
                    type: "link",
                    onClick: _ctx.reload_data_by_view_item
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("视图")
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_a_button, {
                    type: "link",
                    onClick: _ctx.set_current_viwe_default
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("设为默认")
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_a_button, {
                    type: "link",
                    danger: "",
                    onClick: _ctx.delete_view_item
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("删除")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ])
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          (_ctx.need_view_filter)
            ? (_openBlock(), _createBlock(_component_a_button, { key: 0 }, {
                icon: _withCtx(() => [
                  _createVNode(_component_svg_icon, { "icon-name": "WE_DoubleUpDown" })
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" 视图 ")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      (_ctx.form_list.length > 4)
        ? (_openBlock(), _createBlock(_component_a_button, {
            key: 0,
            onClick: _ctx.toggleExpand,
            class: "expand_button"
          }, {
            icon: _withCtx(() => [
              (_ctx.expand)
                ? (_openBlock(), _createBlock(_component_svg_icon, {
                    key: 0,
                    "icon-name": "WE_DoubleUpOutlined"
                  }))
                : (_openBlock(), _createBlock(_component_svg_icon, {
                    key: 1,
                    "icon-name": "WE_DoubleUpDown"
                  }))
            ]),
            default: _withCtx(() => [
              _createTextVNode(" " + _toDisplayString(_ctx.expand ? '收起' : '展开'), 1)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      _createVNode(_component_a_button, {
        onClick: _ctx.clear_form,
        class: "reset_button"
      }, {
        default: _withCtx(() => [
          _createTextVNode("清空")
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}