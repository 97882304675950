import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dropdown_container" }
const _hoisted_2 = { class: "dropdown_wrapper" }
const _hoisted_3 = { class: "dropdown_wrapper_name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_input_password = _resolveComponent("a-input-password")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_dropdown, { trigger: ['click'] }, {
      overlay: _withCtx(() => [
        _createVNode(_component_a_menu, { onClick: _ctx.menuClick }, {
          default: _withCtx(() => [
            (_ctx.userPhone === '18251956234')
              ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "quick_login" }, {
                  default: _withCtx(() => [
                    _createTextVNode("快捷登录")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_a_menu_item, { key: "change_password" }, {
              default: _withCtx(() => [
                _createTextVNode("修改密码")
              ]),
              _: 1
            }),
            _createVNode(_component_a_menu_item, {
              key: "logout",
              style: {"color":"#fc604c"}
            }, {
              default: _withCtx(() => [
                _createTextVNode("退出登录")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.userEmail || _ctx.userPhone), 1),
          _createVNode(_component_svg_icon, {
            class: "dropdown_wrapper_svg",
            "icon-name": "CaretDownOutlined"
          })
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_a_modal, {
      visible: _ctx.showQuickLogin,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showQuickLogin) = $event)),
      title: "快捷登录",
      closable: false,
      maskClosable: "",
      okText: "切换",
      onOk: _cache[3] || (_cache[3] = ($event: any) => (_ctx.quickLogin())),
      centered: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, { label: "手机" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.phone,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phone) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { label: "邮箱" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.email,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_modal, {
      visible: _ctx.showChangePassword,
      "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.showChangePassword) = $event)),
      title: "修改密码",
      closable: false,
      maskClosable: "",
      okText: "修改",
      onOk: _cache[8] || (_cache[8] = ($event: any) => (_ctx.changePasseord())),
      centered: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "passwordForm",
          model: _ctx.passwordItem,
          rules: _ctx.rules,
          labelCol: { span: 4 }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: "旧密码",
              name: "oldPassword"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input_password, {
                  value: _ctx.passwordItem.oldPassword,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.passwordItem.oldPassword) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "新密码",
              name: "newPassword"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input_password, {
                  value: _ctx.passwordItem.newPassword,
                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.passwordItem.newPassword) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "确认密码",
              name: "newPasswordConfirm"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input_password, {
                  value: _ctx.passwordItem.newPasswordConfirm,
                  "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.passwordItem.newPasswordConfirm) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}