export const phoneReg = /^0?(1[3-9])[0-9]{9}$/;
export const emailReg = /^[A-Za-z0-9]+([_.][A-Za-z0-9]+)*@([A-Za-z0-9-]+\.)+[A-Za-z]{2,6}$/;

export const CHINA_CODE = '156';
export const TEMPLATE_URL = 'https://cache.modooplay.com/xlsx/template.xlsx';
export const DOWNLOAD_URL = 'https://cache.modooplay.com/';
export const isLocal = process.env.VUE_APP_ENV === 'local';
export const isDev = process.env.VUE_APP_ENV === 'development';
export const isTest = process.env.VUE_APP_ENV === 'test';
export const isProd =
  window.location.origin.includes('asap.webeyeug.com.cn') || process.env.VUE_APP_ENV === 'production';
export const isOut = location.hostname === '139.196.138.145';
export const isPc = !window.navigator.userAgent.includes('Mobile');
export const isLark = window.navigator.userAgent.includes('Lark');
export const cubeOssTest = 'http://106.15.186.75:8080/';
export const cubeOss = 'http://sophon-resource.webeyeug.com.cn/';
export const is_in_qiankun = window.__POWERED_BY_QIANKUN__;
export const qiankun_path_suffix = '/business/opa';

export const FILE_ICON_MAP: Recordable = {
  txt: 'WE_TxtColor',
  zip: 'WE_ZipColor',
  apk: 'WE_ApkColor',
  video: 'WE_VideoColor',
  link: 'WE_LinkColor',
  excel: 'WE_ExcelColor',
  word: 'WE_WordColor',
  img: 'WE_PictureColor',
  pdf: 'WE_PDFColor',
};
