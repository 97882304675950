
  import { computed, CSSProperties, PropType } from 'vue';

  import { defineComponent } from 'vue';
  import { Spin } from 'ant-design-vue';

  import { SizeEnum } from '@/enums/sizeEnum';
  import { ThemeEnum } from '@/enums/appEnum';

  export default defineComponent({
    name: 'Loading',
    components: { Spin },
    props: {
      tip: {
        type: String as PropType<string>,
        default: '',
      },
      size: {
        type: String as PropType<SizeEnum>,
        default: SizeEnum.LARGE,
        validator: (v: SizeEnum): boolean => {
          return [SizeEnum.DEFAULT, SizeEnum.SMALL, SizeEnum.LARGE].includes(v);
        },
      },
      absolute: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
      loading: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
      background: {
        type: String as PropType<string>,
      },
      theme: {
        type: String as PropType<'dark' | 'light'>,
        default: 'light',
      },
    },
    setup(props) {
      const getStyle = computed((): CSSProperties => {
        const { background, theme } = props;
        const bgColor = background
          ? background
          : theme === ThemeEnum.DARK
          ? 'rgba(0, 0, 0, 0.2)'
          : 'rgba(240, 242, 245, 0.4)';
        return { background: bgColor };
      });

      return { getStyle };
    },
  });
