import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sider_menu = _resolveComponent("sider-menu")!
  const _component_a_layout_sider = _resolveComponent("a-layout-sider")!

  return (_openBlock(), _createBlock(_component_a_layout_sider, {
    class: "opa_app_sider",
    theme: "light",
    width: "256",
    collapsible: "",
    collapsedWidth: 60,
    collapsed: _ctx.collapsed,
    "onUpdate:collapsed": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.collapsed) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_sider_menu, {
        menus: _ctx.MenusByRoles,
        theme: "light",
        collapsed: _ctx.collapsed
      }, null, 8, ["menus", "collapsed"])
    ]),
    _: 1
  }, 8, ["collapsed"]))
}