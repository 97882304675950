
  import { defineComponent } from 'vue';
  import type { PropType } from 'vue';
  import SiderMenuItem from './SiderMenuItem.vue';
  import { MenuItem } from 'types/config';
  import { appStore } from '@/store/modules/app';
  import { urlToList } from '@/utils/page';
  export default defineComponent({
    name: 'SiderMenu',
    components: {
      SiderMenuItem,
    },
    props: {
      menus: Array as PropType<MenuItem[]>,
      collapsed: {
        type: Boolean,
        default: false,
      },
    },
    setup() {},
    data() {
      return {
        selectedKeys: [''],
        openKeys: [''],
      };
    },
    created() {
      this.initMenuKeys();
    },
    methods: {
      handleMenuClick({ key }: { key: string }) {
        appStore.SET_DRAWER_CONFIG(null);
        appStore.SET_ALERT_CONFIG(null);
        appStore.SET_WE_DIALOG(null);
        this.$router.push({ path: key });
      },
      initMenuKeys() {
        const val = this.$route.path;
        this.selectedKeys = [val];
        if (!this.collapsed) {
          const urlList = urlToList(val);
          const len = urlList.length;
          if (len > 1) {
            this.openKeys = [urlList[len - 2]];
          }
        }
      },
    },
    watch: {
      '$route.path': {
        handler() {
          this.initMenuKeys();
        },
      },
      collapsed: {
        handler() {
          this.initMenuKeys();
        },
      },
    },
  });
